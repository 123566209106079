import React, { useContext, useEffect, useState } from 'react'
import { useTheme } from '../../../ThemeProvider'
import { PiClockLight, PiCurrencyInrBold } from 'react-icons/pi'
import { IoPersonSharp } from 'react-icons/io5'
import { FaCertificate, FaEuroSign, FaFacebook, FaHome, FaSitemap, FaUsers, FaWhatsapp } from 'react-icons/fa'
import { ImCopy} from 'react-icons/im'
import { TiFlowMerge } from 'react-icons/ti'
import { LiaUniversitySolid } from 'react-icons/lia'
import { Link, useParams } from 'react-router-dom'
import { useConfig } from '../../../ConfigContext'
import axios from 'axios'
import { useFeedback } from '../../../Context/FeedbackContext'
import { useUser } from '../../../UserContext'
import { TranslationContext } from '../../../TranslationContext'
import { CircularProgress } from '@mui/material'
import { IoMdPrint } from 'react-icons/io'
import { Windowopen } from '../../../componentHome/utils/Validation'

const Modal = ({ isOpen, onClose }) => {

const { theme } = useTheme()
if (!isOpen) return null;

return (
    <div className="fixed inset-0 flex items-center justify-center z-[50]">
      <div className="bg-black opacity-50 absolute inset-0"></div>
      <div className="bg-white rounded-lg p-6 z-10 shadow-lg max-w-sm mx-auto">
        <p className="mt-2 text-gray-600 text-center">The link has been copied to your clipboard.</p>
        <div className="mt-4 text-center">
          <button
            onClick={onClose}
            style={{ backgroundColor: theme?.palette?.primary?.main }} 
            className="text-white px-4 py-2 rounded"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

const SessionDetailModal = ({pagetype}) => {
    const { translations } = useContext(TranslationContext);
    const { id } = useParams()
    const { theme } = useTheme()
    const config = useConfig()
    const { trainingDetails, settrainingDetails } = useFeedback()
    const [isModalOpen, setModalOpen] = useState(false);
    const { selectedUserType } = useUser()


    const getTrainingDetails = async () => {

        const headers = {
            Accept: 'application/json, text/plain',
            APIKey: config?.REACT_APP_API_KEY,
            'Content-Type': 'application/json',
        }

        if(id){
            try {
                const { data } = await axios.get(`${config?.REACT_APP_API_URL}/Training_Details`, {
                    headers: headers,
                    params: {
                        trainingid: id,
                    }
                });
                settrainingDetails(data)
            } catch (error) {
              console.log('Error session type', error);
            }
        }
        else{
            console.log("Training id not found.")
        }
    }

    useEffect(() => {
        if (id && selectedUserType) {
            getTrainingDetails();
        }
    }, [id]); 
  
    function formatDate(datetimeString) {
        // Extract the date part (first 10 characters)
        let dateString = datetimeString.substring(0, 10);
    
        // Split the date into year, month, and day
        let parts = dateString.split("-");
        let year = parts[0]; // Full year
        let month = parts[1];
        let day = parts[2];
    
        // Form the desired date format "dd-mm-yyyy"
        let formattedDate = `${day}-${month}-${year}`;
    
        return formattedDate;
    }

    const createShortUrl = async () => {
        const apiKey = config?.REACT_URL_SHORTNER_KEY;
        const getApiUrl = config?.REACT_URL_SHORTNER_PATH;
        const postApiUrl = config?.REACT_URL_SHORTNER_PATH;
        const link = `${config?.LITTERA_CDN_BASE_URL}/frm_read_course_details.aspx?q=${id}`;
        const postData = { longFormat: link };
    
        console.log("Long URL to shorten:", link);
    
        try {
            const getResponse = await axios.get(getApiUrl, {
                headers: {
                    "Content-Type": "application/json",
                    'API-Key': apiKey,
                }
            });
    
            console.log("Get response:", getResponse.data);
    
            const existingUrls = getResponse.data;
            const matchedUrl = existingUrls.find(url => url.longFormat === link);
    
            if (matchedUrl) {
                console.log("Matched URL found:", matchedUrl.shortFormat);
                return `${config?.LITTERA_CDN_BASE_URL}/shorturl.aspx?url=${matchedUrl.shortFormat}`;
            } else {
                console.log("No match found. Creating new short URL...");
    
                const postResponse = await axios.post(postApiUrl, postData, {
                    headers: {
                        "Content-Type": "application/json",
                        'API-Key': apiKey
                    }
                });
    
                const shortUrl = postResponse.data.shortFormat;
                console.log(`New short URL created: dev.littera.in/shorturl.aspx?url=${shortUrl}`);
                return `${config?.LITTERA_CDN_BASE_URL}/shorturl.aspx?url=${shortUrl}`;
            }
        } catch (error) {
            console.error("Error:", error);
            throw error; 
        }
    };
    
    const handleCopy = async () => {
        try {
            const shortUrl = await createShortUrl();
            const contentToCopy = `
              Title: ${trainingDetails?.courseName}
              Description: ${trainingDetails?.objective}
              Start Date: ${formatDate(trainingDetails?.startDate)}
              Sponsor: ${trainingDetails?.sponsorName}
              Click on the link to register: ${shortUrl}
            `;
    
            await navigator.clipboard.writeText(contentToCopy);
            setModalOpen(true);
        } catch (err) {
            console.error('Error: ', err);
        }
    };

    const handleWhatsappShare = async () => {
        if (!trainingDetails) {
            console.error("Training details are not available.");
            return;
        }
    
        try {
            // Create a short URL
            const shortUrl = await createShortUrl();
    
            // Construct the message
            const message = `Title: ${trainingDetails.courseName || 'N/A'}\n` +
                            `Description: ${trainingDetails.objective || 'N/A'}\n` +
                            `Sponsor: ${trainingDetails.sponsorName || 'N/A'}\n` +
                            `Start Date: ${formatDate(trainingDetails.startDate) || 'N/A'}\n` +
                            `Click on the link to register: ${shortUrl}`;
    
            // Encode the message for URL
            const encodedMessage = encodeURIComponent(message);
    
            // Construct the WhatsApp share URL
            const whatsappURL = `https://api.whatsapp.com/send?text=${encodedMessage}`;
    
            // Open the WhatsApp URL
            Windowopen(whatsappURL);
        } catch (err) {
            console.error('Error creating short URL: ', err);
        }
    };
    
    const handleFacebookShare = () => {
        if (!trainingDetails) {
            console.error("Training details are not available.");
            return;
        }
        
        const link = `${config?.REACT_APP_API_URL}/frm_read_course_details.aspx?q=${id}`;
        
        const encodedUrl = encodeURIComponent(link);
    
        // const facebookURL = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(registrationLink)}&quote=${encodedUrl}`;
        const facebookURL = `https://www.facebook.com/sharer/sharer.php?kid_direct_site=0&sdk=lms&u=${encodedUrl}&display=popup&ref=plugin&src=share_button`;
        
        // Open the Facebook share URL
        Windowopen(facebookURL);
    };
    
    if (trainingDetails === null) {
        return (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
              <CircularProgress />
          </div>
        );
    }

    return (
        <div 
            // style={{ borderColor: theme?.palette?.primary?.main }} 
            style={{ borderColor: theme?.palette?.primary?.main , borderWidth:'1px' , borderStyle: 'solid' }} 
            className={`h-fit max-sm:shrink-0 w-full rounded-xl p-8 relative`}
        >
            <div
                style={{ borderColor: theme?.palette?.primary?.main }} 
                className="flex justify-between max-sm:border-b">
                {/* <div className="flex items-center gap-8 max-sm:flex-col max-sm:items-start "> */}
                <div
                    className="flex items-center gap-8 max-sm:pb-4 max-sm:flex-col max-sm:items-start"
                >
                    <div 
                        style={{ borderColor: theme?.palette?.primary?.main , borderWidth:'1px' , borderStyle: 'solid' }} 
                        className='whitespace-nowrap h-40 w-40 p-2 rounded-full flex items-center justify-center overflow-hidden'
                    >   
                        <img 
                            className='h-full w-full rounded-full'
                            
                            src={
                                trainingDetails?.img_path === "" ? `${config?.LITTERA_CDN_BASE_URL}/theme/images/c1.jpg` : 
                                `${config?.LITTERA_CDN_BASE_URL}/${config?.LITTERA_CDN_PROFILE_PICK_PATH}/${trainingDetails?.img_path}`
                            }
                            onError={(e) => {
                                e.target.src = `${config?.LITTERA_CDN_BASE_URL}/theme/images/c1.jpg`; 
                            }}
                            alt={trainingDetails?.img_path === "" ? `${config?.LITTERA_CDN_BASE_URL}/theme/images/c1.jpg` : `${config?.LITTERA_CDN_BASE_URL}/${config?.LITTERA_CDN_PROFILE_PICK_PATH}/${trainingDetails?.img_path}`}
                        />
                    </div>
                    <div>

                        {/* <h4 title={`${trainingDetails?.courseName || 'Unknown Course'} - ${trainingDetails?.trainingcode || 'No Code'}`}>
                        {
                            trainingDetails?.trg_Setting === null ? 
                            `${trainingDetails?.courseName || 'Unknown Course'} - ${trainingDetails?.trainingcode || 'No Code'}` 
                            : 
                            null
                        }
                        </h4> */}
                        {/* <h4 title={`${trainingDetails?.courseName || 'Unknown Course'} - ${trainingDetails?.trainingcode || 'No Code'}`}>
                            {
                                trainingDetails?.trg_Setting === null ? 
                                `${trainingDetails?.courseName || 'Unknown Course'} - ${trainingDetails?.trainingcode || 'No Code'}` 
                                : 
                                trainingDetails?.trg_Setting?.displaycontrols?.find(control => control.name === "Training_title" && control.isdisplay === 1)?.displaytext || `${trainingDetails?.courseName || 'Unknown Course'} - ${trainingDetails?.trainingcode || 'No Code'}` 
                            }
                        </h4> */}
                        <h4 
                            title={`${trainingDetails?.courseName || 'Unknown Course'} - ${trainingDetails?.trainingcode || 'No Code'}`}
                        >
                        {
                            trainingDetails?.trg_Setting === null
                            ? `${trainingDetails?.courseName || 'Unknown Course'} - ${trainingDetails?.trainingcode || 'No Code'}`
                            : (
                                // Find the display text, or fallback to courseName and trainingCode
                                (trainingDetails?.trg_Setting?.displaycontrols?.find(control => control.name === "Training_title" && control.isdisplay === 1)?.displaytext) 
                                ? `${trainingDetails?.trg_Setting?.displaycontrols?.find(control => control.name === "Training_title" && control.isdisplay === 1)?.displaytext} - ${trainingDetails?.trainingcode || 'No Code'}`
                                : `${trainingDetails?.courseName || 'Unknown Course'} - ${trainingDetails?.trainingcode || 'No Code'}`
                            )
                        }
                        </h4>




                        <h5 className='flex items-center gap-4' ><PiClockLight fontSize={18}/>
                            {translations["Duration"] || "Duration"} : 
                            {
                                trainingDetails?.trg_Setting === null ? 
                                `${trainingDetails?.duration} ${trainingDetails?.durationType}` 
                                : 
                                trainingDetails?.trg_Setting?.displaycontrols?.find(control => control.name === "Duration" && control.isdisplay === 1)?.displaytext || `${trainingDetails?.duration} ${trainingDetails?.durationType}` 
                            }
                        </h5>
                        
                        {
                            trainingDetails?.trg_Setting?.displaycontrols?.find(item => item.id === 8)
                            ? (trainingDetails?.trg_Setting?.displaycontrols.find(item => item.id === 8)?.isdisplay === 1 
                                ? (
                                <h5 className='flex items-center gap-4'>
                                    <IoPersonSharp fontSize={18} />
                                    {translations["Course_Director"] || "Course Director"}: {trainingDetails?.courseDirectorName}
                                </h5>
                                )
                                : null
                            )
                            : (
                            <h5 className='flex items-center gap-4'>
                                <IoPersonSharp fontSize={18} />
                                {translations["Course_Director"] || "Course Director"}: {trainingDetails?.courseDirectorName}
                            </h5>
                            )                        
                        }
                        
                        <h5 className='flex items-center gap-4' >
                            <FaUsers fontSize={18}/> 
                            {translations["Participation"] || "Participation"} : {trainingDetails?.participation_type == '1' ? "Any one can join" : trainingDetails?.participation_type == '2' ? "By Invitation Only" : null}
                        </h5>
                    </div>
                </div>
                {
                    pagetype === 'session' ? 
                    <div className="flex gap-4 h-fit max-sm:absolute max-sm:right-10 top-10">
                        <FaWhatsapp title= {translations["Share_on_whatsapp"] || "Share on whatsapp"} onClick={handleWhatsappShare} fontSize={18} color='#25D366' cursor={"pointer"}/>
                        <FaFacebook title={translations["Share_on_facebook"] || "Share on facebook"} onClick={handleFacebookShare} fontSize={18} color='#1877F2' cursor={"pointer"}/>
                        <ImCopy title={translations["Copy_Invitation"] || "Copy invitation"} onClick={handleCopy} fontSize={18} cursor={"pointer"}/>
                        <Modal isOpen={isModalOpen} onClose={() => setModalOpen(false)} />
                    </div>
                    :
                    null
                }
            </div>

            <div className="flex gap-16 pt-6 max-lg:flex-col max-phone:gap-8 max-lg:gap-4">
                <div className="flex max-lg:w-full w-1/2 flex-col gap-6">
                    <div className='flex gap-2'>
                        <span className='flex whitespace-nowrap items-start gap-4'>
                            <FaCertificate fontSize={18} /> 
                            {translations["Participant_lavel"] || "Participant Level"} :
                        </span>
                        <span className='m-0 flex items-center gap-4' >
                            {trainingDetails?.participantLevel}
                        </span>
                    </div>
                    
                    {
                        selectedUserType == '3' ?
                        <div className='flex gap-2'>
                            <span className='flex whitespace-nowrap items-start gap-4'>
                                <PiCurrencyInrBold fontSize={18} /> 
                                {translations["Fees"] || "Fees"} :
                            </span>
                            <span className='m-0 flex items-center gap-4' >
                                {trainingDetails?.dueFees ?? 'N/A'} /-
                            </span>
                        </div>
                        :
                        null
                    }

                    <div className='flex gap-2'>
                        <span className='flex whitespace-nowrap items-start gap-4'>
                            <PiClockLight fontSize={18} />
                            {translations["Pre-requisite"] || "Fees"} :
                        </span>
                        <span className='m-0 flex items-center gap-4' >
                            {trainingDetails?.prerequiste}
                        </span>
                    </div>

                    <div className='flex  gap-2 relative'>
                        <span className='opacity-0 flex items-start gap-4'>
                            <LiaUniversitySolid fontSize={18} />
                            {translations["Whom_It_Will_Benefit"] || "Whom It Will Benefit"} :
                            {trainingDetails?.benefitted}
                        </span>

                        <span className='absolute  max-lg:w-full flex items-start gap-4' >
                            <span className='whitespace-nowrap'>
                                <LiaUniversitySolid fontSize={18} />
                            </span>
                            <span className='flex items-start gap-4'>
                                {translations["Whom_It_Will_Benefit"] || "Whom It Will Benefit"} : {trainingDetails?.benefitted}
                            </span>
                        </span>
                    </div>
                </div>
                
                <div className="flex flex-col gap-6">
                    <div className='flex gap-6 items-start max-sm:gap-4 max-lg:flex-col'>
                        <span className='flex items-start'>
                            
                            <span className='flex whitespace-nowrap items-start gap-4'>
                                <FaHome fontSize={18} />
                                {translations["Type"] || "Type"} :
                            </span>
                            
                            <span className='m-0 ml-2 flex items-center gap-4' >
                                {
                                    trainingDetails?.trg_Setting === null ? 
                                    `${trainingDetails?.tttt_name}` 
                                    : 
                                    trainingDetails?.trg_Setting?.displaycontrols?.find(control => control.name === "Training_type" && control.isdisplay === 1)?.displaytext || `${trainingDetails?.tttt_name}` 
                                }
                            </span>

                        </span>
                        <span className='flex items-center max-lg:ml-12'>
                            <span className='flex whitespace-nowrap items-start gap-4'>
                                {translations["Residential"] || "Residential"} :
                            </span>
                            <span className='m-0 ml-2 flex items-center gap-4' >
                                {trainingDetails?.resident_status == '1' ? "Yes" : trainingDetails?.resident_status == '2' ? "No" : null}                            
                            </span>
                        </span>
                    </div>
                    <div className='flex gap-2'>
                        <span className='flex whitespace-nowrap items-start gap-4'>
                            <FaEuroSign fontSize={18} />
                            {translations["Sponsored_paidby"] || "Sponsored/Paid By"} : 
                        </span>
                        <span className='m-0 flex items-center gap-4' >
                            {trainingDetails?.sponsorName}
                        </span>
                    </div>
                    
                    <div className='flex gap-2'>
                        <span className='flex whitespace-nowrap items-start gap-4'>
                            <FaSitemap fontSize={18} />
                            {translations["Participant_Type"] || "Participant Type"} :  
                        </span>
                        <span className='m-0 flex items-center gap-4' >
                            {trainingDetails?.participant_type_txt}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SessionDetailModal