import React, { useState } from 'react';
import { Modal, Button, Input, message } from 'antd';
import axios from 'axios';
import styled from 'styled-components';
import { useConfig } from '../../ConfigContext';

const UploadAssignment = ({ closeModal, triggerElementRef }) => {
    const config = useConfig();
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [modalPosition, setModalPosition] = useState({ top: "100px", left: 0 });

    // Handle position based on where user clicks
    const handleOpenModal = (e) => {
        const rect = e.target.getBoundingClientRect();
        setModalPosition({
            top: rect.top + window.scrollY + rect.height, // Position below the element clicked
            left: rect.left + window.scrollX, // Position to the left of the element
        });
    };

    const handleSave = async () => {
        if (!email || !subject) {
            message.error('Please fill in all required fields');
            return;
        }

        const payload = {
            email,
            subject,
            message,
        };

        const headers = {
            'Content-Type': 'application/json',
            APIKey: config.REACT_APP_API_KEY,
        };

        try {
            setLoading(true);
            const response = await axios.post(`${config.REACT_APP_API_URL}/Send_Mail`, payload, { headers });

            if (response.status === 200) {
                message.success('Email sent successfully');
                closeModal();  // Close modal on success
            } else {
                message.error('Failed to send email');
            }
        } catch (error) {
            message.error('An error occurred while sending the email');
        } finally {
            setLoading(false);
        }
    };

    return (
        <StyledModal
            visible={true}
            onCancel={closeModal}
            footer={[
                <Button key="cancel" onClick={closeModal}>
                    Cancel
                </Button>,
                <Button key="ok" type="primary" onClick={handleSave} loading={loading}>
                    Save
                </Button>,
            ]}
            centered={false} // Don't center the modal automatically
            style={{ top: `${modalPosition.top}px`, left: `${modalPosition.left}px` }} // Apply custom position
            bodyStyle={{ padding: 0 }} // Ensure no additional padding
            destroyOnClose={true}
        >
            <div className="modal-header" style={{ display: 'flex', alignItems: 'center', borderBottom: 'none' }}>
                <h3>Compose Mail</h3>
            </div>

            <div className="modal-box" style={{ border: '1px solid #d3c3c3', borderRadius: '5px', padding: '20px', paddingTop: '0px' }}>
                {/* Title Input */}
                <div style={{ marginBottom: 5, marginTop: '20px' }}>
                    <Input
                        placeholder="Enter Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        style={{ width: '100%' }}
                    />
                </div>

                {/* Subject Input */}
                <div style={{ marginBottom: 5, marginTop: '20px' }}>
                    <Input
                        placeholder="Enter Subject"
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                        style={{ width: '100%' }}
                    />
                </div>

                {/* Remarks Input */}
                <div style={{ marginTop: 20 }}>
                    <Input.TextArea
                        placeholder="Enter Description (Optional)"
                        rows={4}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                    />
                </div>
            </div>
        </StyledModal>
    );
};

export default UploadAssignment;

const StyledModal = styled(Modal)`
  .ant-modal {
    width: 55%;  /* Default width for larger screens */
    position: absolute; /* Position absolute for custom positioning */
  }

  /* Media query for mobile devices */
  @media (max-width: 768px) {
    .ant-modal {
      width: 90% !important;  /* Set width to 90% on mobile screens */
    }
  }

  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }

  .left-section {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
`;
