import React, { useContext, useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, CircularProgress } from '@mui/material';
import Breadcrumb from '../commonComponent/Breadcrumbs';
import IconButton from '@mui/material/IconButton';
import RocketIcon from '@mui/icons-material/Rocket';
import MailIcon from '@mui/icons-material/Mail';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Input, Select, DatePicker, Space } from 'antd';
import moment from 'moment';
import axios from 'axios';
import 'antd/dist/reset.css';
import { TranslationContext } from '../../TranslationContext';
import { Link, NavLink } from 'react-router-dom';
import { useConfig } from '../../ConfigContext';
import { useUser } from '../../UserContext';
import { formatDuration } from '../utils/Validation';
import MoneyIcon from '@mui/icons-material/AttachMoney'; // For money icon
import DehazeIcon from '@mui/icons-material/Dehaze'; // For session list icon (or another relevant icon)
import { Dropdown, Menu, Checkbox } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import MailModal from './MailModal';
import { FaRupeeSign } from 'react-icons/fa';  // Import the Rupee icon from react-icons/fa

const { Option } = Select;
const { RangePicker } = DatePicker;

const TrainingList = () => {
  const config = useConfig();
  const { selectedUserType, selectedYear } = useUser();
  const { translations } = useContext(TranslationContext);
  const [meetingData, setMeetingData] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const user = JSON.parse(localStorage.getItem('user'));
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [restriction, setrestriction] = useState(null)
  const userid = user.userdetails.agencyid;
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10); // Number of items per page
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10); // Adjust as needed
  const [totalItems, setTotalItems] = useState(0); // Total items
  const [selectedOption, setSelectedOption] = useState('');
  const [dateRangeVisible, setDateRangeVisible] = useState(false);
  const [searchCriteria, setSearchCriteria] = useState('trainingcode');
  const [searchQuery, setSearchQuery] = useState();
  const [checkedItems, setCheckedItems] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const [sortConfig, setSortConfig] = useState({
    key: '',
    direction: 'asc', // default direction
  });

  if (selectedUserType !== 5) {
    console.log("selectedUserType", selectedUserType);
  }
  const handleSelectChange = (value) => {
    setSelectedOption(value);
    if (value == 'Period') {
      setDateRangeVisible(true);
    } else {
      setDateRangeVisible(false);
    }
  };

  const startDate = `${selectedYear.split('-')[0]}-04-01`;
  const endDate = `${selectedYear.split('-')[1]}-03-31`;

  useEffect(() => {
    const branchid = user.userdetails.branchid;

    // Define your async function to fetch data
    const fetchData = async () => {
      // Construct the URL for GET parameters
      const url = `${config.REACT_APP_API_URL}/Dashboard_Data?usertype=${selectedUserType}&userid=${userid}&startdate=${startDate}&enddate=${endDate}&PageNumber=${currentPage}&PageSize=${pageSize}`;

      // Define the payload object with search criteria (conditionally added)
      let payload = null;  // Initially set payload to null

      // If searchQuery has a value, include the searchCriteria in the payload
      if (searchQuery && searchQuery.trim() !== "") {
        payload = {
          searchCriteria: [
            {
              column: searchCriteria,   // Search column
              value: searchQuery,       // Search value
              condition: '=',           // Search condition (can be 'like', '=', etc.)
              nextoperator: 'Or',       // Next operator for multiple conditions
            },
          ],
        };
      }

      try {
        // Use axios to send a POST request with the payload in the body
        const response = await axios.post(url, payload, {
          headers: {
            'Content-Type': 'application/json',
            'APIKey': config.REACT_APP_API_KEY,
          },
        });

        // If the response is successful, update state with the data
        setMeetingData(response.data.items);
        setTotalItems(response.data.totalRecords); // Set total items from response
        setTotalPages(response.data.totalPages);   // Calculate total pages

        console.log("Response Data", response.data);

        setIsLoading(false);
      } catch (error) {
        // Handle error if the request fails
        setError(error);
        setIsLoading(false);
      }
    };


    // Call the fetchData function
    fetchData();

  }, [selectedYear, config, selectedUserType, userid, currentPage, itemsPerPage]);



  const sortData = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }

    setSortConfig({ key, direction });

    const sortedData = [...meetingData].sort((a, b) => {
      const valueA = a[key] !== undefined && a[key] !== null ? a[key] : ''; // Fallback to empty string
      const valueB = b[key] !== undefined && b[key] !== null ? b[key] : ''; // Fallback to empty string

      console.log('valueA', valueA)
      console.log('valueB', valueB)

      if (key === 't_StartDate' || key === 't_EndDate') {
        const dateA = moment(valueA, 'DD/MM/YYYY').isValid() ? moment(valueA, 'DD/MM/YYYY').valueOf() : 0;
        const dateB = moment(valueB, 'DD/MM/YYYY').isValid() ? moment(valueB, 'DD/MM/YYYY').valueOf() : 0;
        return direction === 'asc' ? dateA - dateB : dateB - dateA;
      } else {
        const strA = valueA.toString().toLowerCase();
        const strB = valueB.toString().toLowerCase();



        console.log('strA', strA)
        console.log('strB', strB)

        if (direction === 'asc') {
          return strA < strB ? -1 : strA > strB ? 1 : 0;
        } else {
          return strA > strB ? -1 : strA < strB ? 1 : 0;
        }
      }
    });

    setMeetingData(sortedData);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(prevPage => prevPage + 1);
    }
  };

  // Fetching data function
  const fetchData = async (updatedSearchCriteria = []) => {
    const branchid = user.userdetails.branchid;

    // Construct the URL for GET parameters
    const startDate = `${selectedYear.split('-')[0]}-04-01`;
    const endDate = `${selectedYear.split('-')[1]}-03-31`;
    const url = `${config.REACT_APP_API_URL}/Dashboard_Data?usertype=${selectedUserType}&userid=${userid}&startdate=${startDate}&enddate=${endDate}&PageNumber=${currentPage}&PageSize=${pageSize}`;

    // Wrap the updatedSearchCriteria in an object with searchCriteria as a key
    let payload = null;  // Initially set payload to null

    // If searchQuery has a value, include the searchCriteria in the payload
    if (searchQuery && searchQuery.trim() !== "") {
      payload = {
        searchCriteria: [
          {
            column: searchCriteria,   // Search column
            value: searchQuery,       // Search value
            condition: '=',           // Search condition (can be 'like', '=', etc.)
            nextoperator: 'Or',       // Next operator for multiple conditions
          },
        ],
      };
    }

    // If the updatedSearchCriteria exists, add it to the payload
    if (updatedSearchCriteria.length > 0) {
      if (payload) {
        payload.searchCriteria = [...payload.searchCriteria, ...updatedSearchCriteria]; // Combine existing search criteria with the new ones
      } else {
        payload = { searchCriteria: updatedSearchCriteria }; // If no other search criteria exist, just use the new ones
      }
    }

    console.log("Payload to be sent: ", payload); // Will log the correct structure of payload

    try {
      // Use axios to send a POST request with the payload in the body
      const response = await axios.post(url, payload, {
        headers: {
          'Content-Type': 'application/json',
          'APIKey': config.REACT_APP_API_KEY,
        },
      });

      // If the response is successful, update state with the data
      setMeetingData(response.data.items);
      setTotalItems(response.data.totalRecords); // Set total items from response
      setTotalPages(response.data.totalPages);   // Calculate total pages

      setIsLoading(false);
    } catch (error) {
      // Handle error if the request fails
      setError(error);
      setIsLoading(false);
    }
  };


  //==================Send Message in Whatshapp==================//
  useEffect(() => {
    fetchData(); // Initial fetch when the component mounts
  }, [selectedYear, currentPage, pageSize, searchQuery, searchCriteria, selectedUserType]);

  // Fetch status options
  useEffect(() => {
    const fetchStatusOptions = async () => {
      try {
        const response = await fetch(`${config.REACT_APP_API_URL}/TrainingStatus`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            APIKey: config.REACT_APP_API_KEY,
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        setStatusOptions(data); // Assuming the response has a "statuses" array
        console.log(data);
      } catch (error) {
        console.error('Error fetching status options:', error);
      }
    };

    fetchStatusOptions();
  }, []);


  const handleCheck = (e, status) => {
    const newCheckedItems = [...checkedItems];

    // Add or remove status.id from checkedItems based on whether the checkbox is checked
    if (e.target.checked) {
      newCheckedItems.push(status.id); // Use status.id for matching
    } else {
      const index = newCheckedItems.indexOf(status.id);
      if (index > -1) {
        newCheckedItems.splice(index, 1);
      }
    }

    // Update the checked items
    setCheckedItems(newCheckedItems);

    // Dynamically create searchCriteria for the selected checkboxes
    const updatedSearchCriteria = newCheckedItems.map((statusId) => ({
      column: 'trainingStatus',   // Column name for filtering
      value: statusId.toString(), // Ensure the value is a string
      condition: '=',             // Equal condition for filtering
      nextoperator: 'or',         // Use "or" to combine multiple conditions
    }));

    // Trigger fetch with updated search criteria
    fetchData(updatedSearchCriteria);  // Now pass updated search criteria wrapped in searchCriteria
    console.log("Updated Search Criteria:", updatedSearchCriteria);
  };


  console.log("meeting", meetingData)

  const handleSearchQueryChange = (value) => {
    if (selectedOption === 'Period' && value) {
      if (Array.isArray(value) && value.length === 2) {
        // Format start and end date from the RangePicker
        const startDate = value[0]?.format('YYYY-MM-DD');
        const endDate = value[1]?.format('YYYY-MM-DD');

        // Log the selected start and end dates to the console
        console.log("Selected Start Date:", startDate);
        console.log("Selected End Date:", endDate);

        // Update searchQuery with the selected date range
        setSearchQuery({ startDate, endDate });
      } else {
        // Reset search query if range is cleared
        setSearchQuery(null);
      }
    } else if (value && value.target) {
      // Update search query for text-based search
      setSearchQuery(value.target.value);
    }
  };


  const handleSearchCriteriaChange = (value) => {
        setSearchCriteria(value);

        if (value === 'Period') {
            setDateRangeVisible(true);  // Show the date range picker when 'Period' is selected
        } else {
            setDateRangeVisible(false);  // Hide the date range picker for other criteria
        }
    };

  if (isLoading) return (<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
    <CircularProgress />
  </div>);
  if (error) return <p>Error: {error.message}</p>;
  const menu = (
    <Menu>
      {statusOptions?.map((status, index) => (
        <Menu.Item key={status.id}>  {/* Use status.id as key */}
          <Checkbox
            checked={checkedItems.includes(status.id)} // Check if the status id is in the checked items
            onChange={(e) => handleCheck(e, status)}  // Pass status directly
          >
            {translations[status.name] || status.name}  {/* Assuming status.name is the label */}
          </Checkbox>
        </Menu.Item>
      ))}
    </Menu>
  );

  const openModal = () => {
    setIsModalVisible(true);  // This will show the modal
  };
  const closeModal = () => {
    setIsModalVisible(false);  // This will hide the modal
  };

  const registerparticipant = async (meeting) => {
    if (meeting?.training_SponsorType == 4) {
      console.log("paid", meeting?.training_SponsorType == 4)
      if (restriction) {
        console.log("Restriction true hai ji")
        // window.open(`${config?.LITTERA_CDN_BASE_URL}/paymentGateway/frm_payment.aspx?id=${meeting?.trainingId}*${user?.userdetails?.agencyid}*100`);
        if (window.XamarinApp && typeof window.XamarinApp.callXamarinFunction === "function") {
          // If XamarinApp is present, call the Xamarin-specific function
          window.XamarinApp.callXamarinFunction(`${config?.LITTERA_CDN_BASE_URL}/paymentGateway/frm_payment.aspx?id=${meeting?.trainingId}*${user?.userdetails?.agencyid}*100`);
        } else {
          // Open a new tab for browsers
          return window.open(`${config?.LITTERA_CDN_BASE_URL}/paymentGateway/frm_payment.aspx?id=${meeting?.trainingId}*${user?.userdetails?.agencyid}*100`);  // This should open the new window or tab
        }
      } else if (!restriction) {
        console.log("Restriction false hai ji")
        if (window.XamarinApp && typeof window.XamarinApp.callXamarinFunction === "function") {
          // If XamarinApp is present, call the Xamarin-specific function
          window.XamarinApp.callXamarinFunction(`${config?.LITTERA_CDN_BASE_URL}/paymentGateway/frm_Payoffline.aspx?id=${meeting?.trainingId}*${user?.userdetails?.agencyid}*100`);
        } else {
          // Open a new tab for browsers
          return window.open(`${config?.LITTERA_CDN_BASE_URL}/paymentGateway/frm_Payoffline.aspx?id=${meeting?.trainingId}*${user?.userdetails?.agencyid}*100`);  // This should open the new window or tab
        }
        // window.open(`${config?.LITTERA_CDN_BASE_URL}/paymentGateway/frm_Payoffline.aspx?id=${meeting?.trainingId}*${user?.userdetails?.agencyid}*100`);
      } else {
        console.log("Restriction nahi hai ji", meeting?.training_SponsorType == 4)
        return
      }
    }
  }
  const paths = [
    { title: translations['Dashboard'] || 'Dashboard', url: '/dashboard' },
    { title: translations['Training_list'] || 'Training List', url: '/training-list' }
  ];

  return (
    <>
      <Breadcrumb paths={paths} />
      <div style={{ display: 'flex', gap: '10px', padding: '16px', backgroundColor: '#E4E7EA', width: '100%', position: 'relative' }}>
        {!dateRangeVisible ? (
          <Input
            placeholder={translations["Search_training"] || "Search Training"}
            style={{ padding: '8px', width: '80%' }}
            value={searchQuery}
            onChange={handleSearchQueryChange}
          />
        ) : (
          <div style={{ width: '80%' }}>
            <RangePicker style={{ padding: '9px', width: "100%" }}
              format="YYYY-MM-DD"
              onChange={handleSearchQueryChange}  // Pass the date range to the handler
            />
          </div>
        )}
        <Select
          placeholder="Training Code"
          className='selectsearch'
          style={{ height: '40px', maxWidth: "400px", width: '30%' }}
          bordered
          value={searchCriteria}
          onChange={handleSearchCriteriaChange}
        >
          <Option value="trainingcode">{translations["Training_code"] || "Training Code"}</Option>
          <Option value="tttt_name">{translations["Training_type"] || "Training Type"}</Option>
          <Option value="t_Name">{translations["Topic"] || "Topic"}</Option>
          <Option value="Period">{translations["Period"] || "Period"}</Option> 
        </Select>
      </div>

      <div style={{ width: '96%', textAlign: 'end', margin: "2%" }}>

        <Dropdown
          overlay={menu}
          trigger={['click']}
          placement="bottomLeft" // Align dropdown to left
        >
          <a
            className="ant-dropdown-link"
            onClick={e => e.preventDefault()}
            style={{
              display: 'inline-block',
              padding: '2px',
              height: '28px',
              border: '1px solid',
              borderRadius: '3px'

            }}
          >
            {translations['Status_filter'] || "Filter By Status"}   <DownOutlined />
          </a>
        </Dropdown>
      </div>

      <div className="table-container">
        <TableContainer component={Paper}>
          <Table>
            <TableHead style={{ backgroundColor: "rgb(66, 139, 202)" }}>
              <TableRow>
                <TableCell
                  style={{ borderLeft: '2px solid #ddd', cursor: 'pointer' }}
                  className='feedbck-titel'
                  onClick={() => sortData('trainingcode')}
                >
                  {translations['Code'] || "Code"}
                  {sortConfig.key === 'trainingcode' ? (sortConfig.direction === 'asc' ? ' ▲' : ' ▼') : ' ▼'}
                </TableCell>
                <TableCell
                  style={{ borderLeft: '2px solid #ddd', cursor: 'pointer' }}
                  className='feedbck-titel'
                  onClick={() => sortData('t_Name')}
                >
                  {translations['Title'] || "Title"}
                  {sortConfig.key === 't_Name' ? (sortConfig.direction === 'asc' ? ' ▲' : ' ▼') : ' ▼'}
                </TableCell>
                <TableCell
                  style={{ borderLeft: '2px solid #ddd', cursor: 'pointer' }}
                  className='feedbck-titel'
                  onClick={() => sortData('trainingCategoryName')}
                >
                  {translations['Category'] || "Category"}
                  {sortConfig.key === 'trainingCategoryName' ? (sortConfig.direction === 'asc' ? ' ▲' : ' ▼') : ' ▼'}
                </TableCell>
                <TableCell
                  style={{ borderLeft: '2px solid #ddd', cursor: 'pointer' }}
                  className='feedbck-titel'
                  onClick={() => sortData('t_StartDate')}
                >
                  {translations['Period'] || "Period"}
                  {sortConfig.key === 't_StartDate' ? (sortConfig.direction === 'asc' ? ' ▲' : ' ▼') : ' ▼'}
                </TableCell>
                <TableCell
                  style={{ borderLeft: '2px solid #ddd', cursor: 'pointer' }}
                  className='feedbck-titel'
                  onClick={() => sortData('tttt_name')}
                >
                  {translations['Training_Type'] || "Training Type"}
                  {sortConfig.key === 'tttt_name' ? (sortConfig.direction === 'asc' ? ' ▲' : ' ▼') : ' ▼'}
                </TableCell>
                <TableCell
                  style={{ borderLeft: '2px solid #ddd', cursor: 'pointer' }}
                  className='feedbck-titel'
                  onClick={() => sortData('participant_type_name')}
                >
                  {translations['Participant_Type'] || " Participant Type"}
                  {sortConfig.key === 'participant_type_name' ? (sortConfig.direction === 'asc' ? ' ▲' : ' ▼') : ' ▼'}
                </TableCell>
                <TableCell
                  style={{ borderLeft: '2px solid #ddd', cursor: 'pointer' }}
                  className='feedbck-titel'
                  onClick={() => sortData('status_txt')}
                >
                  {translations['Status'] || "Status"}
                  {sortConfig.key === 'status_txt' ? (sortConfig.direction === 'asc' ? ' ▲' : ' ▼') : ' ▼'}
                </TableCell>
                <TableCell style={{ borderLeft: '2px solid #ddd' }}></TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {meetingData.map((meeting, index) => (
                <TableRow key={index}>
                  <TableCell style={{ borderLeft: '2px solid #ddd' }} className="feedback-row newtrainlist">
                    <Link className='' style={{ textDecoration: 'none' }} to={`/Training/Training_details/${meeting.trainingId}`}>
                      {meeting.trainingcode}
                    </Link>
                  </TableCell>
                  <TableCell style={{ borderLeft: '2px solid #ddd' }} className="feedback-row">
                    {meeting.t_Name}
                  </TableCell>
                  <TableCell style={{ borderLeft: '2px solid #ddd' }} className="feedback-row">
                    {meeting.trainingCategoryName}
                  </TableCell>
                  <TableCell style={{ borderLeft: '2px solid #ddd' }} className="feedback-row">
                    {moment(meeting.t_StartDate).format('DD/MM/YYYY')} to {moment(meeting.t_EndDate).format('DD/MM/YYYY')}
                  </TableCell>
                  <TableCell style={{ borderLeft: '2px solid #ddd' }} className="feedback-row">
                    {meeting.tttt_name}
                  </TableCell>
                  <TableCell style={{ borderLeft: '2px solid #ddd' }} className="feedback-row">
                    {meeting.participant_type_name}
                  </TableCell>
                  <TableCell style={{ borderLeft: '2px solid #ddd' }} className="feedback-row">
                    {translations[meeting.status_txt] || meeting.status_txt}
                  </TableCell>
                  <TableCell style={{ borderLeft: '2px solid #ddd', display: 'flex', gap: '1px', justifyContent: "space-around", height: "74px" }}>
                    <IconButton aria-label="icons" style={{ padding: "0px" }} onClick={() => (meeting)}>

                      {/* Check the condition and render the appropriate icon(s) */}
                      {meeting.participantstatus == 2 && (
                        <MailIcon onClick={openModal} /> // Show Mail Icon when participantstatus is 2
                      )}
                      {meeting.participantstatus == 0 && meeting.training_SponsorType == 4 && (
                        <>
                          <MailIcon onClick={openModal} />  {/* Mail Icon */}

                          <span style={{ fontSize: "18px", cursor: 'pointer', marginTop: "5px" }} onClick={() => registerparticipant(meeting)}>
                            <FaRupeeSign /> {/* Rupee Icon */}
                          </span>
                        </>
                      )}
                      {meeting.participantstatus == 9 && (
                        <MailIcon onClick={openModal} />  // Show Mail Icon when participantstatus is 9
                      )}
                      {meeting.participantstatus == 1 && (
                        <Link style={{ textDecoration: 'none' }} to={`/Training/Training_details/${meeting.trainingId}`}>
                          <DehazeIcon />
                        </Link>
                        // Show Session List Icon when participantstatus is 1
                      )}
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>


          </Table>
        </TableContainer>
      </div>

      <div className="card-container">
        {meetingData.map((meeting, index) => (
          <div className="card" key={index}>
            <div className="card-header" style={{ backgroundColor: "rgb(66, 139, 202)", color: "white", padding: "10px" }}>
              <h4 className='flex gap-2'>
                {translations['Code'] || "Code"}
                <Link className='text-white' style={{ textDecoration: 'none' }} to={`/Training/Training_details/${meeting.trainingId}`}>
                  {meeting.trainingcode}
                </Link>
              </h4>
            </div>
            <div className="card-body">
              <p><strong>{translations['Title'] || "Title"}:</strong> {meeting.t_Name}</p>
              <p><strong> {translations['Category'] || "Category"}:</strong> {meeting.trainingCategoryName}</p>
              <p><strong> {translations['Period'] || "Period"}:</strong> {moment(meeting.t_StartDate).format('DD/MM/YYYY')} to {moment(meeting.t_EndDate).format('DD/MM/YYYY')}</p>
              <p><strong> {translations['Training_Type'] || "Training Type"}:</strong>  {meeting.tttt_name}</p>
              <p><strong>  {translations['Participant_Type'] || " Participant Type"}:</strong> {meeting.participant_type_name}</p>
              <p><strong> {translations['Status'] || "Status"}:</strong> {translations[meeting.status_txt] || meeting.status_txt}</p>
            </div>
            <div className="card-footer" style={{ display: 'flex', justifyContent: 'space-around', padding: '10px' }}>

              <IconButton aria-label="icons" style={{ padding: "0px" }} onClick={() => (meeting)}>

                {/* Check the condition and render the appropriate icon(s) */}
                {meeting.participantstatus == 2 && (
                  <MailIcon onClick={openModal} /> // Show Mail Icon when participantstatus is 2
                )}
                {meeting.participantstatus == 0 && meeting.training_SponsorType == 4 && (
                  <>
                    <MailIcon onClick={openModal} style={{ fontSize: "22px", marginRight: "20px" }} />  {/* Mail Icon */}

                    <span style={{ fontSize: "18px", cursor: 'pointer', marginTop: "5px" }} onClick={() => registerparticipant(meeting)}>
                      <FaRupeeSign /> {/* Rupee Icon */}
                    </span>
                  </>
                )}
                {meeting.participantstatus == 9 && (
                  <MailIcon onClick={openModal} />  // Show Mail Icon when participantstatus is 9
                )}
                {meeting.participantstatus == 1 && (
                  <Link style={{ textDecoration: 'none' }} to={`/Training/Training_details/${meeting.trainingId}`}>
                    <DehazeIcon />
                  </Link>
                  // Show Session List Icon when participantstatus is 1
                )}
              </IconButton>
            </div>
          </div>
        ))}
      </div>


      <div style={{ textAlign: 'end', margin: '20px 10px' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          {translations["Previous"] || "Previous"}
        </Button>
        <span style={{ margin: '0 10px' }}>Page {currentPage} of {totalPages}</span>
        <Button
          variant="contained"
          color="primary"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          {translations["Next"] || "Next"}
        </Button>
      </div>
      {isModalVisible && <MailModal closeModal={closeModal} />}
    </>
  );
};

export default TrainingList;