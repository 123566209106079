import React, { useEffect, useRef, useState } from 'react';
import FacultyFeedback from './FacultyFeedback';
import ContentFeedback from './ContentFeedback';
import { useFeedback } from '../../Context/FeedbackContext';
import { useConfig } from '../../ConfigContext';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Loader from '../../pages/Loader';
import { CircularProgress } from '@mui/material';

const Feedback = () => {
  const config = useConfig()
  const { id } = useParams()

  const { 
    currentForm, 
    setCurrentForm ,  
    isVisible , 
    setisVisible , 
    facultyFeedback, 
    setFacultyFeedback, 
    contentFeedback, 
    setContentFeedback,
    setLoading,
    loading,
    setQuestions,
    Questions,
    ContentQuestions,
    setContentQuestions,
    FeedbackQuestions,
    setFeedbackQuestions,
    feedback,
    setFeedback,
    selectedSessionContent
  } = useFeedback();
  const feedbackRef = useRef() 

  const toggleHandler = () => {
    if (feedbackRef.current) {
      feedbackRef.current.style.display = 'none';
    }
    setisVisible(false)
    setContentFeedback(null)
    setFacultyFeedback(null)
    setCurrentForm('content')
  };

  const handleNext = () => {
    if (currentForm === 'content') {
      setCurrentForm('faculty');
    }
  };

  const handlePrevious = () => {
    if (currentForm === 'faculty') {
      setCurrentForm('content');
    }
  };

  const Submit = (feedback) => {
    setFeedback()
    setisVisible(false)
    setCurrentForm('content')
    // setContentFeedback(null)
    // setFacultyFeedback(null)
    console.log('ggggg', feedback);
  };

  const getContentQuestions = async () => {
    if (id) {
      const headers = {
        Accept: 'application/json, text/plain',
        'API-Key': config?.REACT_APP_SURVEY_API_KEY,
        'Content-Type': 'application/json',
      };

      try {

        const { data } = await axios.get(`${config?.REACT_APP_SURVEY_API_PATH}/Questions`, {
            headers: headers,
        });

        const contentQuestions = data.filter(question => question.feedbackType === 3);
        const feedbackQuestions = data.filter(question => question.feedbackType === 4);
  
        setQuestions(data);
        setContentQuestions(contentQuestions); 
        setFeedbackQuestions(feedbackQuestions);
  
        setQuestions(data);
        setLoading(false);

      } catch (error) {
        console.log('Error in fetching questions', error);
        setLoading(false);
      }
    } else {
      console.log('No id provided');
      setLoading(false);      
    }
  };

  useEffect(() => {
    const fetchdata = async ()=>{
      await getContentQuestions()
    }
    fetchdata()
    if(selectedSessionContent === null){
      setCurrentForm('faculty')
    }    
  }, [])
  
  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </div>
    ) 
  }

  return (
    <div ref={feedbackRef} className={`fixed  z-50 inset-0 ${isVisible ? "flex"  : "hidden"}  items-center justify-center bg-gray-200 bg-opacity-75`}>
      <div className={`bg-white rounded-lg shadow-lg max-w-3xl max-md:mx-6 w-full p-8`}>
        <div className="text-3xl font-medium capitalize flex justify-between items-center border-b-2 py-4 mb-8 ">
            {currentForm} Feedback
            <button onClick={toggleHandler} className="text-3xl text-gray-500 hover:text-gray-700 focus:outline-none">&times;</button>
        </div>
          {currentForm === 'content' && (
            <ContentFeedback
              onNext={handleNext}
              setContentFeedback={setContentFeedback}
              contentFeedback={contentFeedback}
              facultyFeedback={facultyFeedback}
            />
          )}
          {currentForm === 'faculty' && (
            <FacultyFeedback
              setFacultyFeedback={setFacultyFeedback}
              facultyFeedback={facultyFeedback}
              onPrevious={handlePrevious}
              onSubmit={Submit}
              contentFeedback={contentFeedback}
            />
          )}
      </div>
    </div>
  );
};

export default Feedback;
