import React, { useState, useContext, useEffect } from "react";
import axios from 'axios';
import styled from 'styled-components';
import { Alert, useMediaQuery } from '@mui/material';
import { Layout, Menu, Dropdown, Button, Modal } from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined, UserOutlined, LogoutOutlined, IssuesCloseOutlined, KeyOutlined } from "@ant-design/icons";
import { NavLink, useNavigate } from "react-router-dom";
import ColorLensIcons from "@mui/icons-material/ColorLens"
import HomeIcon from "@mui/icons-material/Home";
import { Select, MenuItem, Box, FormControl } from '@mui/material';
import Laungauge from "../../componentHome/othcomponents/Laungauge";
import "./testNavbar.css"; // Import the CSS file
import { useUser } from "../../UserContext";
import { TranslationContext } from "../../TranslationContext";
import { useConfig } from "../../ConfigContext";
import { useTheme } from "../../ThemeProvider";
import loadConfig from "../../config";
import AboutModal from "../modalcomponents/AboutModal";
import ChangePassword from "../modalcomponents/ChangPassword";
// ransinh
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import LogoutModal from "./LogoutModal ";
import { useFeedback } from "../../Context/FeedbackContext";
const { Sider, Header, Content } = Layout;
const HiddenStyle = styled.div`
  .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input {
    display: none;
  }
`;
const CustomMenuItem = styled(Menu.Item)`
  text-decoration: none !important; /* Remove text-decoration */
  
  &:hover {
    text-decoration: none !important; /* Ensure no text-decoration on hover */
  }
`;
const TestNavbar = ({ children }) => {
  const [showChildren, setShowChildren] = useState(false); // State to control children rendering
  const authenticated = useUser()
  // console.log(authenticated)
  const { theme, toggleTheme } = useTheme();
  const [isUserTypeReady, setIsUserTypeReady] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);

  //const config = useConfig();
  const { selectedUserType, setSelectedUserType, setSelectedYear, setConfigDataurl } = useUser();
  const { fullscreen, setfullscreen } = useFeedback()

  const { translations } = useContext(TranslationContext);
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);
  const [openUserDropdown, setOpenUserDropdown] = useState(false);
  const [openUserDropdown1, setOpenUserDropdown1] = useState(false);
  const [select1Options, setSelect1Options] = useState([]);
  const [select2Value, setSelect2Value] = useState('');
  const [dataa, setDataa] = useState(null);
  const [jsonData, setJsonData] = useState(null);
  const [filteredJsonData, setFilteredJsonData] = useState(null); // State to hold filtered JSON data
  const [activeUpperMenu, setActiveUpperMenu] = useState(null); // State to track active upper menu item
  const [loading, setLoading] = useState(true);
  const [config, setConfig] = useState(null);
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [passwordM, setPasswordM] = useState(false);
  const isMobile = useMediaQuery('(max-width: 768px)'); // Change this breakpoint as needed
  const [isModalOpen, setModalOpen] = useState(false);
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    if (isMobile) {
      setCollapsed(true); // Collapse the sidebar on mobile devices
    } else {
      setCollapsed(false); // Expand the sidebar on larger screens
    }
  }, [isMobile]);
  useEffect(() => {
    // Timer to show children after 2 seconds
    const timer = setTimeout(() => {
      setShowChildren(true);
    }, 1000); // 2 seconds

    // Cleanup timer on unmount
    return () => clearTimeout(timer);
  }, []);
  useEffect(() => {
    const initializeConfig = async () => {
      const configData = await fetchConfig();
      if (configData) {
        setLoading(false);
        fetchData(configData); // Fetch initial data once config is loaded
        // fetchMenuBar(configData); // Fetch additional data once config is loaded
        fetchJsonData(); // Added to fetch JSON data
        if (!selectedUserType && user?.userdetails?.usertype.length > 0) {
          setSelectedUserType(user.userdetails?.usertype[0].usertypeid);
        }
      }
    };
    initializeConfig();
  }, [selectedUserType]);
  // console.log(config)
  useEffect(() => {
    if (selectedUserType) {
      setIsUserTypeReady(true);
    }
  }, [selectedUserType]);

  const fetchConfig = async () => {
    try {
      // console.log("Loading config data...");
      const configData = await loadConfig(); // Assuming loadConfig fetches your config object
      // console.log("Config data loaded:", configData);
      setConfig(configData);
      // console.log("ccc", configData);
      setConfigDataurl(configData);
      //fetchAdditionalData();
      return configData;


    } catch (error) {
      console.error("Error loading config:", error);
    }
  };
  useEffect(() => {
    // Fetch user and client data
    const fetchUserAndClientData = async () => {
      try {
        // Fetch user data
        const user = JSON.parse(localStorage.getItem('user'));
        // console.log(user);

        // Fetch client data
        const clientData = JSON.parse(localStorage.getItem('clientData'));
        // console.log(clientData);

        // Check if both user and clientData exist
        if (user && clientData) {
          // Set loading to false if user and clientData are available
          setLoading(false);
        } else {
          // Simulate loading for 5 seconds
          const timer = setTimeout(() => {
            setLoading(false); // After 5 seconds, set loading to false
            navigate('/'); // Redirect to login page
          }, 500);

          return () => clearTimeout(timer); // Clean up timer on component unmount
        }
      } catch (error) {
        console.error('Error fetching user and clientData:', error);
      }
    };

    fetchUserAndClientData();
  }, [navigate]);
  const user = JSON.parse(localStorage.getItem('user'));
  const clientData = JSON.parse(localStorage.getItem('clientData'));
  const fetchData = async (configData) => {
    try {
      const headers = {
        Accept: "application/json, text/plain",
        APIKey: configData.REACT_APP_API_KEY,
        "Content-Type": "application/json",
      };

      const response = await axios.get(`${configData.REACT_APP_API_URL}/Finacial_year`, {
        headers,
      });

      setSelect1Options(response.data);
      // console.log(response);

      // Set default value directly if not fetching current year
      setSelect2Value('2024-2025');

    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle errors for both `Finacial_year` and `CurrentFinancialYear` here
      setSelect2Value('2024-2025'); // Set default value in case of error
    }
  };

  const handleUpperMenuClick1 = (menuId) => {
    setActiveUpperMenu(activeUpperMenu === menuId ? null : menuId);
  };
  // setSelectedYear(select2Value);
  useEffect(() => {
    // Fetch menu bar data only if configData and userType are ready
    const fetchMenuBar = async () => {
      if (isUserTypeReady && config) {
        try {
          const headers = {
            Accept: 'application/json, text/plain',
            'Content-Type': 'application/json',
            APIKey: config.REACT_APP_API_KEY,
          };

          const usertype = selectedUserType;
          const userid = user?.userdetails?.agencyid;
          const chkpermission = 1;

          const url = `${config.REACT_APP_API_URL}/CheckPermission?usertype=${encodeURIComponent(usertype)}&userid=${encodeURIComponent(userid)}&chkpermission=${chkpermission}`;

          const response = await axios.get(url, { headers });
          // console.log('Menu bar data received:', response);
          setDataa(response.data);
        } catch (error) {
          console.error('Error fetching menu bar data:', error);
        }
      }
    };

    fetchMenuBar();
  }, [isUserTypeReady, config, selectedUserType, user?.userdetails?.agencyid]);

  const fetchJsonData = async () => {
    try {
      const response = await fetch(`${process.env.PUBLIC_URL}/content/GlobalSetting/Form.json`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setJsonData(data);
      // console.log("data", data);
    } catch (error) {
      console.error('Error fetching JSON data:', error);
    }
  };
  useEffect(() => {
    if (select2Value) {
      setSelectedYear(select2Value);
    }
  }, [select2Value, setSelectedYear]);
  useEffect(() => {
    if (jsonData && dataa) {
      // Helper function to filter SubMenu items
      const filterSubMenu = (subMenu) => {
        return subMenu.filter(subItem =>
          dataa.some(dataItem => dataItem.tyfp_formid === subItem.Menuid.toString())
        );
      };

      // Filter jsonData based on matching tyfp_formid in dataa
      const filteredData = jsonData
        .filter(item => {
          // Check if the item or any of its submenus match the criteria
          const hasMatchingSubMenu = item.SubMenu && item.SubMenu.length > 0
            ? filterSubMenu(item.SubMenu).length > 0
            : false;

          return dataa.some(dataItem => dataItem.tyfp_formid === item.Menuid.toString()) || hasMatchingSubMenu;
        })
        .map(item => ({
          ...item,
          SubMenu: item.SubMenu ? filterSubMenu(item.SubMenu) : [] // Filter SubMenu items
        }));

      setFilteredJsonData(filteredData);
      // console.log("Filtered JSON data:", filteredData);
    }
  }, [jsonData, dataa]);


  const handleSelectUserType = (event) => {
    setSelectedUserType(event.target.value);
  };

  const handleSelect2Change = (event) => {
    setSelect2Value(event.target.value);
  };

  const handleLogout = () => {
    localStorage.clear();
    // navigate(config.LITTERA_CDN_BASE_URL);
    window.location.href = config.LITTERA_CDN_BASE_URL
  };
  // const showModal = () => {
  //   setVisible(true);
  // };

  const handleOk = () => {
    handleLogout()
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };


  const handleUserDropdownVisibleChange = (visible) => {
    setOpenUserDropdown(visible);
  };
  const handleUserDropdownVisibleChange1 = (visible) => {
    setOpenUserDropdown1(visible);
  };

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };

  const handleOpenModal = () => {
    setPasswordM(false);
    setOpenModal(true);
    setOpenUserDropdown(false);
  };

  const handleCloseModal = () => {
    setOpenModal(false);

  };
  const showModal = () => {
    // Handle sign out
    setMenuVisible(false);  // Close the menu
    Modal.confirm({
      title: translations["Are_you_sure"] || "Are you sure you want to sign out?", // Translation or fallback text
      onOk: () => {
        // Logic for sign out
        handleOk()
        console.log('Signed out');
      },
      onCancel: () => {
        // Optionally handle cancel logic
        console.log('Sign out canceled');
      },
    });
  };

  const handleChangePassword = () => {

    setOpenModal(false);  // Close the menu
    setPasswordM(!passwordM);

  };
  const handleItemClick = (path) => {
    if (isMobile) {
      setCollapsed(true);
      document.body.style.width = "auto";
      document.body.style.transform = "rotate(0deg)"; 
      setfullscreen(false)
    }
    navigate(path); // Navigate to the desired path
  };

  const handleUserProfile = () => {
    navigate('/user-profile')
    setMenuVisible(false)
  }

  const userMenu = (
    <Menu
      visible={menuVisible} // You can manage visibility of the menu here
      onClick={() => setMenuVisible(false)} // Close the menu if you click anywhere outside
    >
      {/* <Menu.Item key="profile" onClick={() => setMenuVisible(false)}> */}
      <Menu.Item key="profile" onClick={handleUserProfile}>
        <UserOutlined style={{ marginRight: '10px' }} />
         {translations['User_profile']||"User Profile"}
      </Menu.Item>

      <Menu.Item key="change-password" onClick={handleChangePassword}>
        <KeyOutlined style={{ transform: 'rotate(180deg)', marginRight: '10px' }} />
         {translations['Change_password']||"Change Password"}
      </Menu.Item>

      <Menu.Item key="about-littera" onClick={handleOpenModal}>
        <IssuesCloseOutlined style={{ marginRight: '10px' }} />
          {translations['About_littera']||"About Littera"}
      </Menu.Item>

      <Menu.Item key="logout" onClick={showModal}>
        <LogoutOutlined style={{ marginRight: '10px' }} />
          {translations['Sign_Out']||"Sign Out"}
      </Menu.Item>
    </Menu>
  );

  const userMenu1 = (
    <Menu>
      {filteredJsonData?.map((menuItem) =>
        menuItem.SideMenu === 0 ? (
          <Menu key={`menu-${menuItem.Menuid}`}>
            {menuItem.SubMenu.map((subItem) => (
              <Menu.Item key={`sub-${subItem.Menuid}`}>
                <NavLink
                  to={subItem.Path}
                  style={{ textDecoration: 'none', color: 'inherit' }}
                >
                  {translations[subItem.LanguageKey] || subItem.LanguageKey}
                </NavLink>
              </Menu.Item>
            ))}
          </Menu>
        ) : null
      )}
    </Menu>
  );

  // Function to render submenu items
  const renderSubMenuItems = (subMenuItems) => {
    return subMenuItems.map((item) => (
      item.DisplayOnMenu === 1 && (
        <Menu.Item key={`sub-${item.Menuid}`} onClick={() => handleItemClick(item.Path)}>
          <NavLink to={item.Path}>
            {translations[item.LanguageKey] || item.LanguageKey}
          </NavLink>
        </Menu.Item>
      )
    ));
  };


  const renderMenu = () => {
    if (!filteredJsonData) return null;

    return filteredJsonData.map((menuItem) => (
      menuItem.SideMenu === 1 && (
        <Menu.SubMenu
          key={`menu-${menuItem.Menuid}`}
          icon={<i className={menuItem.Icon} style={{ color: theme.palette.primary.main }}></i>}
          title={translations[menuItem.LanguageKey] || menuItem.LanguageKey}
          style={{ color: "#555", fontWeight: "500", fontSize: "16px" }}
        >
          {renderSubMenuItems(menuItem.SubMenu)}
        </Menu.SubMenu>
      )
    ));
  };

  // Function to render upper menu items as icons only
  const renderMenuUpper = () => {
    if (!filteredJsonData) return null;

    return filteredJsonData.map((menuItem) => (
      menuItem.SideMenu === 0 && (
        <Dropdown
          key={`dropdown-${menuItem.Menuid}`}

          overlay={
            <Menu>
              {menuItem.SubMenu.map((subItem) => (

                <Menu.Item
                  key={`sub-${subItem.Menuid}`}
                >
                  <NavLink
                    to={subItem.Path}
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    {translations[subItem.LanguageKey] || subItem.LanguageKey}
                  </NavLink>
                </Menu.Item>
              ))}
            </Menu>
          }

          visible={activeUpperMenu === menuItem.Menuid}
          onVisibleChange={(visible) => handleUpperMenuClick(visible, menuItem.Menuid)}
        >
          <Button
            type="text"
            style={{
              color: 'white',
              height: '20px',
              width: '20px',
              borderRadius: '50px',
              display: 'flex',
              justifyContent: 'center',
              backgroundColor: 'rgba(0,0,0,0.1)',
              padding: '15px',
              margin: '5px',
            }}
          >
            <i className={menuItem.Icon} style={{ color: '#fff' }}> </i>
          </Button>
        </Dropdown>
      )
    ));
  };

  const handleUpperMenuClick = (visible, menuId) => {
    setActiveUpperMenu(visible ? menuId : null);
  };


  const handleThemeChange = (event) => {
    toggleTheme(event.target.value);
    setOpen(false);
  };
  const handleIconClick = () => {
    setOpen(!open); // Toggle dropdown when icon is clicked
  };


  return (
    <>
      {/* <Modal
       title={translations["Logout"] ? translations["Logout"] : "Logout"}
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>  {translations["Are_you_sure"] || "Are you sure you want to log out?"}</p>
      </Modal> */}
      <Layout >
        <Sider trigger={null} collapsible collapsed={collapsed} width={280}>
          <div
            className="logo"
            style={{
              height: "32px",
              margin: "16px",
              background: "#ffffff",
            }}
          />
          <Menu style={{ opacity: fullscreen ? 0 : 1 }} mode="inline" defaultSelectedKeys={["1"]} className="custom-menu">
            <div className="mainwrapper">
              <div className="leftpanel">
                <div className={`menu-top-blue ${collapsed ? "collapsed" : ""}`} style={{ backgroundColor: theme.palette?.primary.main, }}>
                  {!collapsed && (
                    <img
                      src={`${config?.LITTERA_CDN_BASE_URL}/${clientData.dashboarD_LOGO}`} // Access client data here
                      alt="Logo"
                      className="navbar-logo"
                      style={{ height: "31px", marginRight: "20px" }}
                    />
                  )}
                </div>
                <div className="media profile-left">
                  <a className="pull-left profile-thumb">
                    <img
                      src={`${config?.LITTERA_CDN_BASE_URL}/${config?.LITTERA_CDN_PROFILE_PICK_PATH}/${user?.userdetails?.photopath}`}
                      alt="Profile"
                      className="img-circle"
                      onError={(e) => {
                        e.target.src = require('../../../src/assest/user_img.png'); // Default image path
                      }}
                    />
                  </a>

                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <h4>{user?.userdetails?.username}</h4>
                    <div style={{ display: 'flex' }}>
                      <select
                        value={selectedUserType} // Default to 5 if selectedUserType is not set
                        onChange={handleSelectUserType}
                        style={{ marginRight: '10px', width: '90px', paddingTop: '3px', paddingBottom: '3px', fontSize: "10px" }}
                        disabled={selectedUserType == '5'} // Disable select when selectedUserType is 5
                      >
                        {user?.userdetails?.usertype.map((type) => (
                          <option key={type.usertypeid} value={type.usertypeid}>
                            {type.usertypename}
                          </option>
                        ))}
                      </select>


                      <select
                        value={select2Value}
                        onChange={handleSelect2Change}
                        style={{ marginRight: '10px', width: "90px", paddingTop: "3px", paddingBottom: "3px", fontSize: "10px" }}
                      >
                        {select1Options.map((option, index) => (
                          <option key={index} value={option}>{option}</option>
                        ))}
                      </select>

                    </div>
                  </div>
                </div>

                <Menu
                  mode="inline"
                  defaultSelectedKeys={["1"]}
                  className="custom-menu"
                >
                  <Menu.Item key="1" icon={<HomeIcon style={{ fontSize: "27px", color: theme.palette.primary.main }} />}>
                    <NavLink
                      to="/dashboard"
                      style={{ color: "#555", fontWeight: "500", fontSize: "16px" }}
                      onClick={() => handleItemClick("/dashboard")} // Ensure handleItemClick is called on click
                    >
                      {translations["Dashboard"] || "Dashboard"}
                    </NavLink>
                  </Menu.Item>

                  {renderMenu()}
                </Menu>
              </div>
            </div>
          </Menu>
        </Sider>
        <Layout >
          <Header
            style={{
              padding: 0,
              background: theme.palette.primary.main,
              display: fullscreen ? "none" : 'flex',
              justifyContent: "space-between",
              alignItems: "center",

            }}
          >
            {/* Left Section */}
            <div>
              <Button
                type="text"
                icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                onClick={toggleSidebar}
                style={{
                  fontSize: "16px",
                  width: 40,
                  height: 40,
                  border: "1px solid white",
                  color: "white",
                  borderRadius: "50px",
                  marginLeft: "5px",
                }}
              />
            </div>

            {/* Middle Section */}

            {/* Right Section */}
            <div className="sidermunu-upper" style={{ display: "flex", alignItems: "center" }}>


              {/* Upper Menu Icons */}
              <Menu mode="horizontal" style={{ background: theme.palette.primary.main, border: "none" }} className="this-uppermenu">
                {renderMenuUpper()}
              </Menu>
              <Laungauge
              />

              <HiddenStyle style={{ display:"none"}}>
                <Select
                  value={theme}
                  onChange={handleThemeChange}
                  onClick={handleIconClick}
                  IconComponent={() => (
                    <ColorLensIcons style={{ color: 'white', fontSize: 18, cursor: "pointer", right: "5px" }} />
                  )}
                  className="hideSelectIcon" // Apply the class here
                  style={{
                    color: 'white',
                    padding: '0px',

                  }}
                  open={open}
                >
                  <MenuItem value="#428bca">Blue Theme</MenuItem>
                  <MenuItem value="red">Red Theme</MenuItem>
                </Select>
              </HiddenStyle>

              <Dropdown
                overlay={userMenu}
                onVisibleChange={handleUserDropdownVisibleChange}
                visible={openUserDropdown}

              >
                <Button
                  type="text"
                  style={{
                    color: "white",
                    fontSize: "16px",
                    marginRight: "10px",
                  }}
                >
                  <img
                    src={require('../../../src/assest/user_img.png')}
                    alt="User"
                    style={{
                      width: "28px",
                      height: "28px",
                      borderRadius: "50%",
                    }}
                  />
                </Button>
              </Dropdown>
              <Dropdown overlay={userMenu1}
                onVisibleChange={handleUserDropdownVisibleChange1}
                visible={openUserDropdown1}
              >
                <div style={{ display: 'flex', alignItems: 'center',display:"none" }}>
                  <ControlPointIcon
                    className="mobile-icon"
                    style={{
                      color: "white",
                      fontSize: "3rem",
                      marginRight: "10px",
                    }}
                  />

                </div>
              </Dropdown>

              <AboutModal open={openModal} handleClose={handleCloseModal} />
              <ChangePassword passwordM={passwordM} handleChangePassword={handleChangePassword} />
            </div>
          </Header>


          {showChildren ? children : <div style={{
          }} className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-white z-50">
            <div
              className="animate-spin rounded-full h-20 w-20 border-t-4 border-b-4 border-gray-900"></div>
          </div>}
        </Layout>
      </Layout>

    </>
  );
};

export default TestNavbar;