import ReactGA from 'react-ga';
const trackingID = process.env.GOOGLE_ANALYTICS_MEGER_ID||"G-K2SYXSTCGD"
// Initialize Google Analytics with your tracking ID (replace with your GA tracking ID)
const savedConfig = JSON.parse(localStorage.getItem('configData'));
console.log(savedConfig); // Logs the saved config object
//console.log(savedConfig.GOOGLE_ANALYTICS_MEGER_ID);
export const initGA = () => {
  ReactGA.initialize(savedConfig?.GOOGLE_ANALYTICS_MEGER_ID||"G-K2SYXSTCGD"); // Replace with your Google Analytics tracking ID
};

// Function to log page views
export const logPageView = () => {
  ReactGA.pageview(window.location.pathname + window.location.search);
};