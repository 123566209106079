import React, { useEffect, useState, useContext } from "react";
import { Form, Select, Button, Tag, Typography, Space, Modal } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import axios from 'axios';
import 'antd/dist/reset.css'; // Import Ant Design CSS
import Breadcrumb from "../commonComponent/Breadcrumbs";
import { useUser } from "../../UserContext";
import { useFeedback } from "../../Context/FeedbackContext";
import { TranslationContext } from "../../TranslationContext";
const { Option } = Select;
const TestDescription = () => {
  const { translations } = useContext(TranslationContext);

  const location = useLocation();
  const navigate = useNavigate();
  const { setTestDataOther, setTestalldata, setPayload } = useUser();
  const { selftestData } = useFeedback()
  const configDataurl = useUser();
  const config = configDataurl?.configDataurl;
  const [thiscat, setThiscat] = useState()
  const [categories, setCategories] = useState([]);
  const [skillTags, setSkillTags] = useState([]);
  const [difficultyLevels, setDifficultyLevels] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedSkillTags, setSelectedSkillTags] = useState([]);
  const [selectedDifficultyLevel, setSelectedDifficultyLevel] = useState('');
  const [testData, setTestData] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);
  const [isTestStartModal, setIsTestStartModal] = useState(false); // new state to control navigation

  console.log('selftestData', selftestData)

  const {
    keyword,
    trainingid,
    sessionid,
    trainingcategoryid,
    tagsArray
  } = location.state || {};
  let dataArray = [];
  var tagewords = "";
  console.log("keyword", keyword);
  // You can log commonData for debugging purposes
  if (keyword) {
    let splitData = keyword.split(',');  // Split kar rahe hain string ko
    dataArray.push(...splitData);  // Spread operator se alag elements push kar rahe hain
  }
  const commonValues = dataArray.filter(item => tagsArray.some(tag => tag.toLowerCase() === item.toLowerCase()));
  tagewords = commonValues;
  console.log("commonValues", commonValues)

  // Fetching categories based on trainingcategoryid
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        let categoriesRes;

        if (trainingcategoryid) {
          categoriesRes = await axios.get(`${config.REACT_APP_API_URL}/Categories?categoryid=${trainingcategoryid}`, {
            headers: { APIKey: config?.REACT_APP_API_KEY }
          });
        } else {
          categoriesRes = await axios.get(`${config.REACT_APP_API_URL}/Categories`, {
            headers: { APIKey: config?.REACT_APP_API_KEY }
          });
        }

        setCategories(categoriesRes.data);
        console.log("categories", categories);
        if (categoriesRes.data.length > 0) {
          if (trainingcategoryid) {
            const foundCategory = categoriesRes.data.find(
              category => category.trainingCategoryId == trainingcategoryid
            );
            setThiscat(foundCategory?.trainingCategoryName)
            if (foundCategory) {
              setSelectedCategory(foundCategory.trainingCategoryId);
            }
          }
        }
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };
    fetchCategories();
  }, [config, trainingcategoryid]);

  // Fetching skill tags based on selected category and keyword
  useEffect(() => {
    const usecategory = selectedCategory || trainingcategoryid
    if (usecategory) {
      const fetchSkillTags = async () => {
        try {
          let apiUrl = `${config.REACT_APP_EVAL_API_PATH}/Tests/Category_Question_Tags?trainingcategoryid=${usecategory}`;

          if (keyword) {
            apiUrl += `&keyword=${encodeURIComponent(keyword)}`;
          }

          const skillTagsRes = await axios.get(apiUrl, {
            headers: { "API-Key": config?.REACT_APP_API_KEY }
          });

          let filteredTags = skillTagsRes.data;
          // if (keyword) {
          //   filteredTags = skillTagsRes.data.filter(tag =>
          //     tag.tags.toLowerCase().includes(keyword.toLowerCase())
          //   );
          // }

          if (filteredTags.length === 0) {
            setSkillTags(filteredTags);
          } else {
            setSkillTags(filteredTags);
          }
        } catch (error) {
          console.error('Error fetching skill tags:', error);
          setSkillTags([]);
        }
      };

      fetchSkillTags();
    } else {
      setSkillTags([]);
    }
  }, [selectedCategory, keyword, config]);

  // Automatically select skill tags based on the keyword if matches exist
  useEffect(() => {
    if (keyword && skillTags.length > 0) {
      const matchingTags = skillTags.filter(tag =>
        tag.tags.toLowerCase().includes(keyword.toLowerCase())
      );

      if (matchingTags.length > 0) {
        setSelectedSkillTags(matchingTags.map(tag => tag.tags.toLowerCase()));
      }
    }
  }, [keyword, skillTags]);
  useEffect(() => {

    const fetchData = async () => {
      try {
        // Step 1: Fetch selfTest data
        let urlPath = `${config.REACT_APP_API_URL}/Get_Self_Test_Configuration`;
        if (trainingid) {
          urlPath += `?trainingid=${trainingid}`;
        }

        const selfTestRes = await axios.get(urlPath, {
          headers: { APIKey: config?.REACT_APP_API_KEY }
        });

        // Step 2: Fetch difficulty levels data after selfTest data
        const difficultyLevelsRes = await axios.get(`${config.REACT_APP_EVAL_API_PATH}/QuestionDifficulties`, {
          headers: { "Content-Type": "application/json", "API-Key": config?.REACT_APP_API_KEY }
        });

        // Set the state with the data from both API responses
        setTestData(selfTestRes.data.no_of_question);
        setTestalldata(selfTestRes.data);
        setDifficultyLevels(difficultyLevelsRes.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    // Trigger the fetchData function
    fetchData();


    fetchData();
  }, [config]);

  // Handlers for form changes
  const handleCategoryChange = (value) => {
    setSelectedCategory(value);
  };

  const handleSkillTagsChange = (value) => {
    setSelectedSkillTags(value);
  };

  const handleDifficultyLevelChange = (value) => {
    setSelectedDifficultyLevel(value);
  };

  // Form submit handler
  const handleSubmit = async () => {
    if (!selectedCategory || (!selectedSkillTags.length && !commonValues.length)) {
      setModalContent("Skill tags not found."); // Set the error message
      setIsModalVisible(true); // Show the modal
      return;
    }

    // Check if selectedSkillTags are valid for the selectedCategory
    const invalidTags = selectedSkillTags.filter(tag =>
      !skillTags.some(validTag => validTag.tags.toLowerCase() === tag.toLowerCase())
    );

    if (invalidTags.length > 0) {
      setModalContent("Skill tags not found.");
      setIsModalVisible(true);
      return;
    }

    const commonValuesString = commonValues.join(",");
    const skillSet = selectedSkillTags.join(",") || commonValuesString;
    const payload = {
      skillSet,
      questionCount: testData,
      questionDifficutyID: selectedDifficultyLevel,
      trainingCategory: selectedCategory,
      trainingid: trainingid,
      sessionid: sessionid
    };

    try {
      const response = await axios.post(`${config.REACT_APP_EVAL_API_PATH}/TestQuestions/Questions`, payload, {
        headers: {
          "API-Key": config.REACT_APP_API_KEY
        }
      });

      setPayload(payload);
      const responseData = response.data;
      setTestDataOther(responseData);
      const hasNullQuestionID = responseData.questions.some(question => question.questionID === null);

      if (hasNullQuestionID || responseData.questions.length < testData) {
        setModalContent('Number of Questions in selected tags and level are less than the required questions to conduct the test. Please Add More Questions.');
        setIsTestStartModal(false);  // We don't want navigation here
        setIsModalVisible(true);
      } else {
        // Show the modal with the restriction message before navigating
        setModalContent('Once you start the test, you cannot exit without submitting it. Do you want to continue?');
        setIsTestStartModal(true);  // We want navigation here
        setIsModalVisible(true);
      }
    } catch (error) {
      console.error('Error submitting data:', error);
    }
  };

  const handleModalOk = () => {
    // Close the modal
    setIsModalVisible(false);

    // Only navigate if it's the "test start" modal
    if (isTestStartModal) {
      // Navigate to start test page
      navigate('/start-test');
    }
  };




  // Modal handlers

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  const paths = [
    { title: translations["Dashboard"] || 'Dashboard', url: '/dashboard' },
    { title: translations["Self_assessment"] || 'Self Test', url: '' }
  ];

  return (
    <>
    
      <Breadcrumb paths={paths} />
      <div style={{
        padding: '24px',
        backgroundColor: '#f9f9f9',
        borderRadius: '8px',
        boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
        marginLeft: "1%",
        margin: 'auto',
        marginTop: '10px',
        width: "98%"
      }}>
        <Typography.Title level={4} style={{ marginBottom: '16px' }}>
          {translations["Test_description"] || "Test Description"}
        </Typography.Title>

        <Form layout="vertical" onFinish={handleSubmit}>
          {trainingcategoryid ? (
            <>
              <Form.Item label={translations["Category"] || "Category"} name="category">
                <Select
                  defaultValue={trainingcategoryid}
                  value={selectedCategory}  // This ensures the dropdown reflects the selected category
                  onChange={handleCategoryChange}
                  placeholder={translations["Select a category"] || "Select a category"}
                  allowClear
                  disabled
                >
                  <Option key={trainingcategoryid} value={trainingcategoryid}>
                    {thiscat}
                  </Option>
                </Select>
              </Form.Item>
              <Form.Item label="Skill Tags" name="skillTags">
                <Select
                  mode="multiple"
                  defaultValue={commonValues}
                  onChange={handleSkillTagsChange}  // Update state when selection changes
                  placeholder="Select skill tags"
                  tagRender={(props) => (
                    <Tag color="blue" {...props}>
                      {props.value}
                    </Tag>
                  )}
                  style={{ width: '100%' }} // Ensures the select takes full width
                  disabled
                >

                  {skillTags.map((tag) => (
                    dataArray.filter(value => value.toLowerCase() === tag.tags.toLowerCase()).length > 0 ? (
                      <Option
                        selected={true}
                        value={tag.tags}
                        style={{ display: 'flex', height: "38px", padding: "10px" }}
                      >
                        {tag.tags}
                      </Option>
                    ) : ""
                  ))}

                </Select>
              </Form.Item>
            </>
          ) : (
            <>
              <Form.Item label={translations["Category"] || "Category"} name="category" rules={[{ required: true, message: translations['Please select a category!'] || 'Please select a category!' }]}>
                <Select
                  value={selectedCategory}  // This ensures the dropdown reflects the selected category
                  onChange={handleCategoryChange}
                  placeholder={translations["Select a category"] || "Select a category"}
                  allowClear
                  loading={categories.length === 0}
                >
                  {categories.map(category => (
                    <Option key={category.trainingCategoryId} value={category.trainingCategoryId}>
                      {category.trainingCategoryName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item label={translations["Skill_tags"] || "Skill Tags"} name="skillTags" rules={[{ required: true, message: translations['Please select skill tags!'] || 'Please select skill tags!' }]}>
                <Select
                  mode="multiple"
                  value={selectedSkillTags}
                  onChange={handleSkillTagsChange}
                  placeholder={translations["Select skill tags"] || "Select skill tags"}
                  tagRender={(props) => (
                    <Tag color="blue" {...props}>
                      {props.value}
                    </Tag>
                  )}
                >
                  {skillTags.map(tag => (
                    <Option key={tag.id} value={tag.tags.toLowerCase()}>
                      {tag.tags.toLowerCase()}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </>
          )}
          <Form.Item label={translations["Difficulty_level"] || "Difficulty Level"} name="difficultyLevel">
            <Select value={selectedDifficultyLevel} onChange={handleDifficultyLevelChange} placeholder={translations["Select difficulty level"] || "Select difficulty level"} allowClear>
              {difficultyLevels.map(level => (
                <Option key={level.id} value={level.questionDifficultyID}>
                  {level.description.toUpperCase()}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item>
            <Space style={{ display: "flex", justifyContent: "space-between" }}>
              <Button type="default" style={{ backgroundColor: '#f0f0f0' }}>
                {translations["Close"] || "Close"}
              </Button>
              <Button type="primary" htmlType="submit">
                {translations["Start"] || "Start"}
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </div>

      <Modal
        // title="Invalid Tags"
        visible={isModalVisible}
        onOk={handleModalOk}
       // onCancel={handleModalCancel}
        okText="OK"
      >
        <p>{modalContent}</p>
      </Modal>
    </>
  );
};

export default TestDescription;
