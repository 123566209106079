import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, CircularProgress, Dialog,
} from '@mui/material';
import { Input, Select } from 'antd';
import 'antd/dist/reset.css'; // Import Ant Design styles
import "react-toastify/dist/ReactToastify.css";
import { useParams } from 'react-router-dom';
import { useUser } from '../../../UserContext';
import { useConfig } from '../../../ConfigContext';
import { TranslationContext } from '../../../TranslationContext';
import { FaDownload, FaPrint } from 'react-icons/fa';
import { RiFileExcel2Fill } from 'react-icons/ri';
import { useTheme } from '../../../ThemeProvider';
import { useFeedback } from '../../../Context/FeedbackContext';
import * as XLSX from 'xlsx';
import { toast } from 'react-toastify';
import { Windowopen } from '../../../componentHome/utils/Validation';
import printData from '../../../componentHome/commonComponent/Print';

const { Option } = Select;

const TrainingProgressReport = () => {
    const { translations } = useContext(TranslationContext);
    const { id } = useParams()
    const { selectedUserType } = useUser();
    const config = useConfig();
    const [ReportData, setReportData] = useState([]);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [reporttotalPages, setReportTotalPages] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [sessionType, setsessionType] = useState(null);
    const { theme } = useTheme()
    const user = JSON.parse(localStorage.getItem('user'));
    const userid = user?.userdetails?.agencyid;
    const [ trainingDetails, settrainingDetails ] = useState(null)
    const [selectedOption, setSelectedOption] = useState('Session Subject');

    const getCurrentDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = String(date.getFullYear()).slice(2);
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        return `${day}-${month}-${year} `;
    };
    
    useEffect(() => {
        const getTrainingDetails = async () => {

            const headers = {
                Accept: 'application/json, text/plain',
                APIKey: config?.REACT_APP_API_KEY,
                'Content-Type': 'application/json',
            }
    
            if(id){
                try {
                    const { data } = await axios.get(`${config?.REACT_APP_API_URL}/Training_Details`, {
                        headers: headers,
                        params: {
                            trainingid: id,
                        }
                    });
                    settrainingDetails(data)
                } catch (error) {
                  console.log('Error session type', error);
                }
            }
            else{
                console.log("Training id not found.")
            }
        }

        getTrainingDetails()
    }, [selectedUserType])

    const getSessionType = async () => {
        // setLoading(true);
        const headers = {
          Accept: 'application/json, text/plain',
          APIKey: config?.REACT_APP_API_KEY,
          'Content-Type': 'application/json',
        };
    
        try {
          const { data } = await axios.get(`${config?.REACT_APP_API_URL}/SessionType`, {
            headers: headers,
          });
          setsessionType(data);
          // setLoading(false);
          console.log("sessionType", sessionType)
        } catch (error) {
          console.log('Error session type', error);
          // setLoading(false);
        }
    };

    useEffect(() => {
        getSessionType()
    }, [])

    useEffect(() => {
        const fetchData = async () => {
            const pageSize = 10;  
            // const participantUrl = `${config?.REACT_APP_API_URL}/TrainingProgressReport?trainingid=${id}&PageNumber=${currentPage}&PageSize=${pageSize}&participantid=${userid}`;
            // const nonParticipantUrl = `${config?.REACT_APP_API_URL}/TrainingProgressReport?trainingid=${id}&PageNumber=${currentPage}&PageSize=${pageSize}`;
            const participantUrl = `${config?.REACT_APP_API_URL}/TrainingProgressReport?trainingid=${id}&PageNumber=${currentPage}&PageSize=${pageSize}&participantid=${userid}`;
            const nonParticipantUrl = `${config?.REACT_APP_API_URL}/TrainingProgressReport?trainingid=${id}&PageNumber=${currentPage}&PageSize=${pageSize}`;

            const url = selectedUserType == 5 ? participantUrl : nonParticipantUrl

            const headers = {
                Accept: "application/json, text/plain",
                APIKey: config.REACT_APP_API_KEY,
                "Content-Type": "application/json",
            };

            try {
                const response = await axios.post(url ,null , 
                    { headers: headers }
                );
                console.log(response?.data)
                setReportData(response?.data || []);  
                setReportTotalPages(Math.ceil(response?.data.totalCount / pageSize));  // Adjust based on API response
                const paginationHeader = response.headers['x-pagination'];
                // const paginationData = JSON.parse(paginationHeader);
                console.log("Pagination Data:", paginationHeader);
                setIsLoading(false);
            } catch (error) {
                console.log(error)
                setError(error);
                setIsLoading(false);
            }
        };
        fetchData();
    }, [config, selectedUserType, currentPage]);

    const printTable = () => {
        // const printWindow = window.open('', '_blank');
    
        // Convert sessions data to table rows (HTML string)
        const sessionRows = ReportData?.items?.map((session, index) => {
            return `
                <tr key="${session.ttttt_session_id || index}">
                    ${
                        trainingDetails.trg_type === 2 ? 
                            `<td>${session.ttttt_session_no}</td>` 
                        :
                            `<td>${getCurrentDate(session.ttttt_session_dt)}</td>
                             <td>${session.ttttt_session_time}</td>`
                    }
                    <td>
                        ${sessionType?.find((type) => type.id == session.ttttt_type)
                            ? `<span class='flex items-start gap-2'>
                                <i class="pr-2 text-gray-800 ${sessionType?.find((type) => type.id == session.ttttt_type)?.displayClass} text-xl"></i>
                                <span>${session.ttttt_subject ? session.ttttt_subject : ''} ${session.ttttt_content_desc ? session.ttttt_content_desc : ''}</span>
                            </span>`
                            : 'N/A'
                        }
                    </td>

                    <td>${session.facultyname || 'N/A'}</td>
                    ${
                        selectedUserType == 5 ? 
                            `<td>${session?.completiondetail?.map(item => item.status === 'Completed' ? 'Yes' : 'No').join(', ') || 'N/A'}</td>`
                        :
                            `<td>${ session?.noofcompletion === null ? 0 : session?.noofcompletion }</td>`
                    }
                </tr>
            `;
        }).join("");
    
        // Dynamically create table headers based on conditions
        const tableHeaders = `
            <thead>
                <tr>
                    ${
                        trainingDetails.trg_type === 2 ?
                            `<th>${translations["Srno"] || "Sr No"}</th>` 
                        : 
                            `<th>${translations["Date"] || "Date"}</th>
                             <th>${translations["Time"] || "Time"}</th>`
                    }
                    <th>${translations["Session_Subject"] || "Session Subject"}</th>
                    <th>${translations["Faculty"] || "Faculty"}</th>
                    ${
                        selectedUserType == 5 ?
                            `<th>${translations["Completed"] || "Completed"}</th>`
                        :
                            `<th>${translations["Session Completed By"] || "Session Completed By"}</th>`
                    }
                </tr>
            </thead>
        `;
        
        const printStr = `
            <html>
                <head>
                    <title>Training Progress Report</title>
                    <style>
                        body {
                            font-family: Arial, sans-serif;
                            margin: 20px;
                        }
                        table {
                            width: 100%;
                            border-collapse: collapse;
                        }
                        th, td {
                            border: 1px solid #ddd;
                            padding: 8px;
                            text-align: center;
                        }
                        th {
                            background-color: #f2f2f2;
                        }
                        h2 {
                            text-align: center;
                            margin-bottom: 20px;
                        }
                        h3 {
                            text-align: center;
                            margin-bottom: 30px;
                        }
                    </style>
                </head>
                <body>
                    <h2>Training Progress Report : ${trainingDetails?.trainingcode}</h2>
                    <table>
                        ${tableHeaders}
                        <tbody>
                            ${sessionRows}
                        </tbody>
                    </table>
                </body>
            </html>
        `
        printData(printStr, config);
        

        // Print HTML document
        // printWindow.document.write(`
        //     <html>
        //         <head>
        //             <title>Training Progress Report</title>
        //             <style>
        //                 body {
        //                     font-family: Arial, sans-serif;
        //                     margin: 20px;
        //                 }
        //                 table {
        //                     width: 100%;
        //                     border-collapse: collapse;
        //                 }
        //                 th, td {
        //                     border: 1px solid #ddd;
        //                     padding: 8px;
        //                     text-align: center;
        //                 }
        //                 th {
        //                     background-color: #f2f2f2;
        //                 }
        //                 h2 {
        //                     text-align: center;
        //                     margin-bottom: 20px;
        //                 }
        //                 h3 {
        //                     text-align: center;
        //                     margin-bottom: 30px;
        //                 }
        //             </style>
        //         </head>
        //         <body>
        //             <h2>Training Progress Report : ${trainingDetails?.trainingcode}</h2>
        //             <table>
        //                 ${tableHeaders}
        //                 <tbody>
        //                     ${sessionRows}
        //                 </tbody>
        //             </table>
        //         </body>
        //     </html>
        // `);

        // printWindow.document.close();
        // printWindow.print();
    };

    const downloadExcel = () => {
        // Prepare the session rows as an array of objects for the Excel file
        const sessionRows = ReportData?.items?.map((session) => {
            let baseRow = {
                "Session Subject": sessionType?.find((type) => type.id == session.ttttt_type)
                    ? `${session.ttttt_subject ? session.ttttt_subject : ''} ${session.ttttt_content_desc ? session.ttttt_content_desc : ''}`
                    : 'N/A',  // Session Subject / Content
                "Faculty": session.facultyname || 'N/A',  // Faculty Name
            };
    
            // Conditionally add 'Sr No', 'Date', 'Time' based on trg_type and session data
            if (trainingDetails.trg_type == 2) {
                // If trg_type is 2, show 'Sr No' only if session.ttttt_session_no exists
                if (session.ttttt_session_no) {
                    baseRow["Sr No"] = session.ttttt_session_no;  // Use session number if available
                }
            } else {
                // If trg_type is not 2, do NOT include 'Sr No' at all
                baseRow["Date"] = getCurrentDate(session.ttttt_session_dt);  // Date (only if trg_type != 2)
                baseRow["Time"] = session.ttttt_session_time;  // Time (only if trg_type != 2)
            }
    
            // Conditionally set the "Completed" or "Session Completed By" column based on selectedUserType
            if (selectedUserType == 5) {
                baseRow["Completed"] = session?.completiondetail?.map(item => item.status === 'Completed' ? 'Yes' : 'No').join(', ') || 'N/A';
            } else {
                baseRow["Session Completed By"] = session?.noofcompletion === null ? 0 : session?.noofcompletion || 0 ;  // Assuming `session_completed_by` exists
            }
    
            return baseRow;
        });
    
        // Define the headers dynamically based on trg_type and available data
        let headers = [];
    
        if (trainingDetails.trg_type == 2) {
            // When trg_type is 2, show only "Sr No", "Session Subject", "Faculty" if session.ttttt_session_no exists
            if (sessionRows.some(row => row["Sr No"])) {
                headers.push("Sr No");
            }
            headers.push("Session Subject");
            headers.push("Faculty");
        } else {
            // When trg_type is not 2, show "Date", "Time", "Session Subject", "Faculty" but NO "Sr No"
            headers.push("Date");
            headers.push("Time");
            headers.push("Session Subject");
            headers.push("Faculty");
        }
    
        // Conditionally include "Completed" or "Session Completed By" based on selectedUserType
        if (selectedUserType == 5) {
            headers.push("Completed");
        } else {
            headers.push("Session Completed By");
        }
    
        // Create the worksheet with the sessionRows data and dynamic headers
        const ws = XLSX.utils.json_to_sheet(sessionRows, { header: headers });
    
        // Apply bold styling to header cells
        headers.forEach((header, index) => {
            const cell = ws[XLSX.utils.encode_cell({ r: 0, c: index })];
            if (cell) {
                cell.s = { font: { bold: true } };  // Apply bold styling
            }
        });
    
        // Adjust column widths dynamically based on data and headers
        const columnWidths = headers.map(header => {
            if (header === "Sr No") return { width: 10 };
            if (header === "Session Subject") return { width: 40 };
            if (header === "Faculty") return { width: 30 };
            if (header === "Completed" || header === "Session Completed By") return { width: 20 };
            if (header === "Date") return { width: 20 };
            if (header === "Time") return { width: 15 };
            return { width: 12 }; // Default width
        });
    
        // Set the column widths based on the headers
        ws['!cols'] = columnWidths;
    
        // Create a new workbook and append the worksheet
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Training Progress");
    
        // Generate the buffer
        const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    
        // Create a Blob from the buffer
        const blob = new Blob([wbout], { type: 'application/octet-stream' });
    
        // Create a link element to trigger the download
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `Training_Progress_Report_${trainingDetails?.trainingcode}.xlsx`;
    
        // Append the link to the body
        document.body.appendChild(link);
    
        // Trigger the download
        link.click();
    
        // Clean up by removing the link element
        document.body.removeChild(link);
    };
    
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleSelectChange = (value) => {
        setSelectedOption(value);
    };

    const allCompleted = ReportData?.items?.every((test) => test?.noofcompletion !== null && test?.noofcompletion !== 0);

    const Get_Certificate_Signatory = async () => {
        const headers = {
          Accept: 'application/json, text/plain',
          APIKey: config?.REACT_APP_API_KEY,
          'Content-Type': 'application/json',
        };
    
        try {
          const { data } = await axios.get(`${config?.REACT_APP_API_URL}/Get_Certificate_Signatory?trainingid=${id}`, {
            headers: headers,
          });
          console.log("Get_Certificate_Signatory", data)
          return data 
        } catch (error) {
          console.log('Error session type', error);
        }
    };

    const Generate_Certificate = async () => {
        const headers = {
          Accept: 'application/json, text/plain',
          APIKey: config?.REACT_APP_API_KEY,
          'Content-Type': 'application/json',
        };
    
        try {
          const { data } = await axios.get(`${config?.REACT_APP_API_URL}/Generate_Certificate?trainingid=${id}&participantid=${user?.userdetails?.agencyid}&branchid=${user?.userdetails?.branchid}&APPURL=${config?.LITTERA_CDN_BASE_URL}&Logo_Path=${config?.LITTERA_CDN_PROFILE_PICK_PATH}`, {
            headers: headers,
          });
          return data 
        } catch (error) {
          console.log('Error session type', error);
        }
    };

    const downloadCertificate = async () => {
        const approved = await Get_Certificate_Signatory(); 
        
        if (approved) {
            if (approved?.length > 0) {
                const Certificate = await Generate_Certificate();
                const printWindow = window.open('', '_blank');
    
                printWindow.document.write(`
                    <style>
                        @font-face {
                            font-family: Brush Script MT;
                            src: url(Theme/fonts/BRUSHSCI.TTF);
                        }
                        @page {
                            margin: 0;
                        }
                        @media print {
                            body {
                                margin: 0px;
                                box-shadow: none;
                            }
                        }
                    </style>
                    ${Certificate}
                `);
                
                // Close the document stream
                printWindow.document.close();
                
                // Wait until the window content is fully loaded before printing
                printWindow.onload = function() {
                    printWindow.print(); 
                };
            } else {
                alert('Training Completed Successfully, Certificate Under Process. Please Try Again Later.');
                return;
            }
        } else {
            return;
        }
    };
    
    if (isLoading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </div>
        );
    }

    if (error) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <p>Error fetching data: {error.message}</p>
            </div>
        );
    }

    if (!Array.isArray(ReportData?.items)) {
        return <div>No data available or incorrect format.</div>;
    }
    
    const filteredItems = ReportData?.items?.filter(test => {
        // Safeguard against undefined/null values in the data
        const facultyName = test.facultyname ? test.facultyname.toLowerCase() : '';
        const trainingSubject = test.ttttt_subject ? test.ttttt_subject.toLowerCase() : '';
        const trainingDescription = test.ttttt_content_desc ? test.ttttt_content_desc.toLowerCase() : '';
    
        if (selectedOption === 'Session Subject') {
          // If Session Subject is selected, filter by subject or description
          return (
            trainingSubject.includes(searchTerm.toLowerCase()) ||
            trainingDescription.includes(searchTerm.toLowerCase())
          );
        } else if (selectedOption === 'Faculty') {
          // If Faculty is selected, filter by facultyName
          return facultyName.includes(searchTerm.toLowerCase());
        }
    
        return false; // Default case (no selection)
    });

    // const filteredItems = ReportData.items.filter(test => {
    //   // Safeguard against undefined/null values in facultyName and trainingCode
    //   const facultyName = test.facultyname ? test.facultyname.toLowerCase() : '';
    //   const trainingSubject = test.ttttt_subject ? test.ttttt_subject.toLowerCase() : '';
    //   const trainingDescription = test.ttttt_content_desc ? test.ttttt_content_desc.toLowerCase() : '';

    //   return (
    //     facultyName.includes(searchTerm.toLowerCase()) || 
    //     trainingSubject.includes(searchTerm.toLowerCase()) || 
    //     trainingDescription.includes(searchTerm.toLowerCase())  
    //   );
    // });

    return (
        <>
            <div style={{ display: 'flex', gap: '10px', padding: '16px', backgroundColor: '#E4E7EA', width: '100%' }}>
                <Input
                    placeholder="Search"
                    style={{ width: '80%', padding: '8px' }}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />

                <Select
                    className='selectsearch'
                    style={{ height: '40px', maxWidth: "400px", width: '30%' }}
                    bordered
                    defaultValue="Session Subject"
                    onChange={handleSelectChange}
                >
                    <Option value="Session Subject">Session Subject</Option>
                    <Option value="Faculty">Faculty</Option>
                </Select>
            </div>
            
            <div className='flex-wrap max-sm:gap-4 flex py-6 max-sm:flex-col max-sm:items-start justify-between items-end gap-2'>
                <p
                  className='flex gap-2 max-sm:items-start max-sm:flex-col items-center m-0'
                >
                    <span>
                      Training : {trainingDetails?.t_Name} [ {trainingDetails?.trainingcode} ] 
                    </span>
                    { 
                        selectedUserType == 5 ?
                        null
                        :
                        <span>
                            No Of Participants : {trainingDetails?.noOfParticipants}
                        </span>
                    }
                </p>

                <div className='flex gap-2'>
                    {
                        allCompleted ?
                        <button
                            onClick={downloadCertificate}
                            title='Download Certificate'
                            className='text-white bg-[#808080] py-3 px-5 w-fit flex items-center gap-4 rounded-lg'
                        >
                            <FaDownload/> 
                        </button>
                        :
                        null
                    }
                    
                    <button
                      onClick={printTable}
                      title='Print'
                      className='text-white bg-[#808080] py-3 px-5 w-fit flex items-center gap-4 rounded-lg'
                      >
                      <FaPrint/> 
                    </button>

                    <button
                      onClick={downloadExcel}
                      title='Save as excel'
                      className='text-white bg-[#808080] py-3 px-5 w-fit flex items-center gap-4 rounded-lg'
                      >
                      <RiFileExcel2Fill/> 
                    </button>
                </div>
            </div>

            <div className="table-container">
                <TableContainer component={Paper} style={{ width: "100%", margin: "auto" }}>
                    <Table>
                    <TableHead style={{ backgroundColor: "rgb(66, 139, 202)" }}>
                        <TableRow>
                        {trainingDetails.trg_type === 2 ? (
                            <TableCell style={{ borderLeft: '2px solid #ddd' }} className="feedbck-titel">SR #</TableCell>
                        ) : (
                            <>
                            <TableCell style={{ borderLeft: '2px solid #ddd' }} className="feedbck-titel">Date</TableCell>
                            <TableCell style={{ borderLeft: '2px solid #ddd' }} className="feedbck-titel">Time</TableCell>
                            </>
                        )}
                        <TableCell style={{ borderLeft: '2px solid #ddd' }} className="feedbck-titel">Session Subject</TableCell>
                        <TableCell style={{ borderLeft: '2px solid #ddd' }} className="feedbck-titel">Faculty</TableCell>
                        {selectedUserType == 5 ? (
                            <TableCell style={{ borderLeft: '2px solid #ddd' }} className="feedbck-titel">Completed</TableCell>
                        ) : (
                            <TableCell style={{ borderLeft: '2px solid #ddd' }} className="feedbck-titel">Session Completed By #</TableCell>
                        )}
                        </TableRow>
                    </TableHead>

                    <TableBody>
                {/* {ReportData?.items(test =>
                    (test.testName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                        test.trainingCode.toLowerCase().includes(searchTerm.toLowerCase())) */}
                    {filteredItems?.map((test, index) => (                        
                        <TableRow key={index}>
                            {trainingDetails.trg_type === 2 ? (
                            <TableCell style={{ borderLeft: '2px solid #ddd' }} className="feedback-row">{test.ttttt_session_no}</TableCell>
                            ) : (
                            <>
                                <TableCell style={{ borderLeft: '2px solid #ddd' }} className="feedback-row">{getCurrentDate(test.ttttt_session_dt) || 'N/A'}</TableCell>
                                <TableCell style={{ borderLeft: '2px solid #ddd' }} className="feedback-row">{test.ttttt_session_time || 'N/A'}</TableCell>
                            </>
                            )}

                            <TableCell style={{ borderLeft: '2px solid #ddd' }} className="feedback-row">
                            {sessionType?.find((type) => type.id == test.ttttt_type) ? (
                                <span className="flex items-start gap-2">
                                <i className={`pr-2 text-gray-800 ${sessionType?.find((type) => type.id == test.ttttt_type)?.displayClass} text-xl`}></i>
                                <span>{test.ttttt_subject} {test.ttttt_content_desc}</span>
                                </span>
                            ) : 'N/A'}
                            </TableCell>

                            <TableCell style={{ borderLeft: '2px solid #ddd' }} className="feedback-row">
                            {test.facultyname || 'N/A'}
                            </TableCell>

                            {selectedUserType == 5 ? (
                            <TableCell style={{ borderLeft: '2px solid #ddd' }} className="feedback-row">
                                {test?.completiondetail?.some(item => item.status === 'Completed') ? 'Yes' : 'No' || 'N/A'}
                            </TableCell>
                            ) : (
                            <TableCell style={{ borderLeft: '2px solid #ddd' }} className="feedback-row">
                               { test?.noofcompletion === null ? 0 : test?.noofcompletion }                        
                            </TableCell>
                            )}
                        </TableRow>
                        ))}
                    </TableBody>
                    </Table>
                </TableContainer>
            </div>          
            
            <div className="card-container p-0">
                {/* {ReportData?.items?.map((test, index) => ( */}
                {filteredItems?.map((test, index) => (                        
                    <div className="card mb-0" key={index}>
                    <div className="card-body mb-0">
                        {trainingDetails.trg_type === 2 ? (
                        <p><strong>Sr No : </strong> {test.ttttt_session_no}</p>
                        ) : (
                        <>
                            <p><strong>Date :</strong> {getCurrentDate(test.ttttt_session_dt)}</p>
                            <p><strong>Time :</strong> {test.ttttt_session_time}</p>
                        </>
                        )}
                        <p className="flex gap-2">
                        {/* <strong className='whitespace-nowrap'>Session Subject :</strong> */}
                        {sessionType?.find((type) => type.id == test.ttttt_type) ? (
                            <span className="flex-wrap items-start gap-2">
    
                            <strong className=''>Session Subject : </strong>

                            <i className={`pr-2 max-sm:pr-0 text-gray-800 ${sessionType?.find((type) => type.id == test.ttttt_type)?.displayClass} text-xl`}></i>
                            <span>
                                {test.ttttt_subject} {test.ttttt_content_desc}
                            </span>
                            </span>
                        ) : (
                            'N/A'
                        )}
                        </p>
                        <p><strong>Faculty :</strong> {test.facultyname || 'N/A'}</p>

                        {selectedUserType == 5 ? (
                        <p><strong>Completed :</strong> {
                            test?.completiondetail?.some(item => item.status === 'Completed') 
                            ? 'Yes' 
                            : 'No' 
                            || 'N/A'  // Default to N/A if there's no status match
                        }</p>
                        ) : (
                        <p><strong>Session Completed By # :</strong> 
                           { test?.noofcompletion === null ? 0 : test?.noofcompletion }                        
                        </p>
                        )}
                    </div>
                    </div>
                ))}
            </div>
           
            
            {/* <div className="flex pagination-container" style={{ display: 'flex', justifyContent: 'end', padding: '16px' }}> */}
            <div className="pagination-container" style={{ display: 'flex', justifyContent: 'end', padding: '16px' }}>
                <Button
                    variant="contained"
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    style={{ marginRight: "5px" }}>
                    Previous
                </Button>
                <Button
                    variant="contained"
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                >
                    Next
                </Button>
            </div>


        </>
    );
};

export default TrainingProgressReport;