import React, { useContext, useEffect, useState } from 'react'
import { useConfig } from '../../../ConfigContext'
import { useUser } from '../../../UserContext'
import { useFeedback } from '../../../Context/FeedbackContext'
import axios from 'axios'
import DashboardTrainings from './DashboardTrainings'
import { useTheme } from '../../../ThemeProvider'
import { CircularProgress, useMediaQuery } from '@mui/material'
import { TranslationContext } from '../../../TranslationContext'

const TrainingsDashboard = () => {
    const config = useConfig()
    const { theme } = useTheme()
    const [searchQuery, setsearchQuery] = useState('');
    const [searchCriteria, setsearchCriteria] = useState('trainingcode');
    const { translations } = useContext(TranslationContext);

    const { 
        loading ,
        setLoading , 
        activeButton, setActiveButton ,
        dashboard_Data, setdashboard_Data ,
        isActive,
        dashboardpagesize, setdashboardpagesize,
        dashboardpagenumber, setdashboardpagenumber
    } = useFeedback()
    const { 
        selectedYear , selectedUserType, 
    } = useUser()
    const user = JSON.parse(localStorage.getItem('user'));

    const handleToggle = (buttonType) => {
      setActiveButton(buttonType); 
      setdashboardpagenumber(1)
      setsearchQuery('')
    };
      
    // useEffect(() => {
    //     setdashboardpagenumber(1)
    //     // setActiveButton('myTrainings')
    //     setActiveButton('alltrainings')
    // }, [])
    
    // useEffect(() => {
    //     setdashboardpagenumber(1)
    //     if(dashboard_Data?.totalRecords > 0){
    //         setActiveButton('myTrainings')
    //     }else{
    //         setActiveButton('alltrainings')
    //     }
    // }, [dashboard_Data])

    

    // useEffect(() => {
    //     // Only run if activeButton is not set yet
    //     if (activeButton === 'myTrainings') {
    //         if(searchQuery === ''){
    //             if (dashboard_Data?.totalRecords > 0) {
    //               setActiveButton('myTrainings');
    //             } else {
    //               setActiveButton('alltrainings');
    //             }
    //         }
    //     }
    // }, [dashboard_Data, activeButton]);


    const getDashboardData = async (input,criteria) => {

        if (user) {
            setLoading(true);
            const headers = {
                Accept: "application/json, text/plain",
                APIKey: config.REACT_APP_API_KEY,
                "Content-Type": "application/json",
            };

            const startdate = `${selectedYear.split('-')[0]}-04-01`
            const enddate = `${selectedYear.split('-')[1]}-03-31`

            const payload = {

                searchCriteria: [
                    {
                        column: 'trainingcode',
                        value: input,
                        condition: '=',
                        nextoperator: 'AND',
                    },
                ],
            };

            try {
                const searchdashboardataurl = `${config?.REACT_APP_API_URL}/Dashboard_Data?usertype=${selectedUserType}&userid=${user?.userdetails?.agencyid}&startdate=${startdate}&enddate=${enddate}&PageNumber=${1}&PageSize=${dashboardpagesize}`;
                const searchallDashboardDataurl = `${config?.REACT_APP_API_URL}/Dashboard_All_Trg_Data?usertype=${selectedUserType}&userid=${user?.userdetails?.agencyid}&startdate=${startdate}&enddate=${enddate}&PageNumber=${1}&PageSize=${dashboardpagesize}`;
                const dashboardataurl = `${config?.REACT_APP_API_URL}/Dashboard_Data?usertype=${selectedUserType}&userid=${user?.userdetails?.agencyid}&startdate=${startdate}&enddate=${enddate}&PageNumber=${dashboardpagenumber}&PageSize=${dashboardpagesize}`;
                const allDashboardDataurl = `${config?.REACT_APP_API_URL}/Dashboard_All_Trg_Data?usertype=${selectedUserType}&userid=${user?.userdetails?.agencyid}&startdate=${startdate}&enddate=${enddate}&PageNumber=${dashboardpagenumber}&PageSize=${dashboardpagesize}`;
                
                let url = '';
                let requestData = null;
            
                // if (searchQuery === '' || input === undefined) {
                if (input === undefined) {
                    // If no payload (search query empty or input undefined), fallback to regular URLs
                    url = activeButton === 'myTrainings' ? dashboardataurl : allDashboardDataurl;
                } else {
                    // If payload exists (search query or input is defined), use search URLs
                    url = activeButton === 'myTrainings' ? searchdashboardataurl : searchallDashboardDataurl;
                    requestData = payload ? payload : null;  // Only attach payload if it exists
                }
            
                // Make the API request with the chosen URL and requestData (which can be null if no payload)
                const { data } = await axios.post(url, requestData, {
                    headers: headers,
                });
            
                // Update state with the response data
                setdashboard_Data(data);
                setLoading(false);
                return data;
            } catch (error) {
                // Handle error
                setLoading(false);
                console.error(error); // Optionally log the error
            }

        } else {
            setLoading(false);
        }
    };

    // useEffect(() => {
    //     console.log("testtt",dashboard_Data?.totalRecords)
    //     if (dashboard_Data?.totalRecords > 0) {
    //       setActiveButton('myTrainings');
    //     } else {
    //       setActiveButton('alltrainings');
    //     }
    // }, [])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getDashboardData();
                if (data?.totalRecords > 0) {
                    setActiveButton('myTrainings');
                } else {
                    setActiveButton('alltrainings');
                }
            } catch (error) {
                console.error('Error fetching dashboard data:', error);
            }
        };
    
        fetchData();
    }, []);

    const handlePaste = async (e) => {
        e.preventDefault();  // Prevent default paste behavior
    
        // Get pasted value and sanitize it
        let pastedValue = e.clipboardData.getData('Text');
        pastedValue = pastedValue.replace(/[^0-9]/g, ''); // Remove non-numeric characters
    
        // console.log("Pasted Value:", pastedValue);  // Debugging log
    
        // If nothing is pasted, return early and do nothing
        if (pastedValue === '') {
            // console.log("No value pasted, returning early.");
            return;
        }
    
        setsearchQuery(pastedValue);  // Update state with sanitized pasted value
    
        // Trigger function when pasted value reaches 8 characters
        if (pastedValue.length === 8) {
            // console.log("Calling function with pasted value:", pastedValue);  // Debugging log
            await getDashboardData(pastedValue, searchCriteria);  // Pass pasted value directly
        } else {
            // console.log("Pasted value is not 8 characters, calling getDashboardData with empty value");
            await getDashboardData('');  // Call function with empty value if pasted value is not 8 characters
        }
    };

    const handleInputChange = async (e) => {
        const value = e.target.value.replace(/[^0-9]/g, '');  // Sanitize input
    
        setsearchQuery(value);  // Update the state with the sanitized value
    
        // Check if the value has 8 characters (for calling yourFunction or getDashboardData)
        if (value.length === 8) {
            console.log("Calling yourFunction with value:", value);  // Debugging log
            await yourFunction(value, searchCriteria);  // Pass the sanitized value directly
        } else if (value === '') {
            console.log("Input is empty, calling getDashboardData");
            await getDashboardData();  // Call getDashboardData when input is empty
        } else {
            console.log("Input length is not 8, no function called.");
        }
    };

    const handleSelectChange = async (e) => {
        const selectedValue = e.target.value;
        setsearchCriteria(selectedValue);
    
        if (searchQuery.length === 8) {
          await yourFunction(searchQuery, selectedValue);
        }
    };

    const yourFunction = async (input, criteria) => {
        console.log('8 digits entered!', input, 'Criteria:', criteria);
        await getDashboardData(input, criteria);
    };

    useEffect(() => {
        const fetchData = async () => {
            if (selectedYear) {
                await getDashboardData()
            }
        };
    
        fetchData();
    }, [selectedUserType,dashboardpagenumber,selectedYear,activeButton]);

    const handleShowMore = ()=>{
        setdashboardpagenumber(prev => prev + 1)
    }

    const handleShowless = ()=>{
        if( dashboardpagenumber !== 1 ){
            setdashboardpagenumber(prev => prev - 1)
        }
    }

    return (
    <div className='h-full w-full'>

        <div className='w-full p-8 bg-[#E4E7EA] flex gap-10 '>
            <input 
                type="text" 
                placeholder='Search Training' 
                className='w-[80%] py-1 px-4' 
                value={searchQuery}
                onChange={handleInputChange}
                maxLength={8}
                minLength={8}
                onPaste={handlePaste} 
            />
            <select
                onChange={handleSelectChange} 
                className='cursor-pointer py-3 h-full w-96 border-2'
            >  
                <option className='w-full' value="trainingcode">Training Code</option>
            </select>
        </div>

        <div className='w-full flex justify-end my-6'>
            <button
                style={{ backgroundColor: activeButton === 'alltrainings' ? theme?.palette?.primary?.main : "#ececec" }} 
                className={`py-4 px-8 max-sm:px-4 max-sm:py-2 font-medium max-sm:text-lg min-w-60 ${
                    activeButton === 'alltrainings' ? ' text-white rounded-l-lg' : ' text-black rounded-r-lg'
                    // activeButton === 'alltrainings' ? 'bg-[#337AB7] text-white rounded-l-lg' : 'bg-[#ececec] text-black rounded-l-lg'
                }`}
                onClick={() => handleToggle('alltrainings')}
            >
            {translations["All" || 'All']} {translations["Training" || 'training']}
            
            </button>
            <button
                style={{ backgroundColor: activeButton === 'myTrainings' ? theme?.palette?.primary?.main : "#ececec" }} 
                // style={{activeButton === 'myTrainings' ? theme?>}}
                className={`py-4 px-2 font-medium max-sm:text-lg w-52 ${
                // activeButton === 'myTrainings' ? 'bg-[#337AB7] text-white rounded-r-lg' : 'bg-[#ececec] text-black rounded-r-lg'
                activeButton === 'myTrainings' ? ' text-white rounded-r-lg' : ' text-black rounded-r-lg'
                }`}
                onClick={() => handleToggle('myTrainings')}
            >
                {translations["My_training" || 'My Trainings']} 
            </button>
        </div>
        {
            loading && dashboardpagenumber === 1 && searchQuery === '' ?
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </div>
            :
            <DashboardTrainings/>
        }
        
        <div className="flex justify-center w-full gap-4  mt-4">
            {
                dashboardpagenumber > 1 && (
                <button
                    onClick={handleShowless}
                    style={{ backgroundColor: theme?.palette?.primary?.main }}
                    className={`px-4 py-2 text-white rounded transition`}
                >
                    {translations["Previous" || 'Previous']} 
                </button>
                )
            }

            {
                dashboard_Data?.totalPages > dashboard_Data?.currentPage && (
                <button
                    onClick={handleShowMore}
                    style={{ backgroundColor: theme?.palette?.primary?.main }} 
                    className={`px-4 py-2 text-white rounded transition`}
                >
                    {translations["Next" || 'Next']} 
                </button>
                )
            }
        </div>
    </div>
  )
}

export default TrainingsDashboard