import React, { useContext, useEffect, useRef, useState } from "react";
import { useConfig } from "../../../ConfigContext";
import axios from "axios";
import { useFeedback } from "../../../Context/FeedbackContext";
import { useTheme } from "../../../ThemeProvider";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { IoFlask, IoFolder, IoRocketSharp, IoTrash } from "react-icons/io5";
import printData from '../../../componentHome/commonComponent/Print'
import {
  FaBook,
  FaEdit,
  FaFilter,
  FaHeadphones,
  FaPrint,
  FaRegPlayCircle,
  FaSuitcase,
  FaUser,
} from "react-icons/fa";
import {
  RiFileExcel2Fill,
  RiProgress1Line,
  RiWhatsappFill,
} from "react-icons/ri";
import { MdScreenShare, MdFeedback } from "react-icons/md";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  List,
  ListItem,
  ListItemText,
  Collapse,
  ListItemSecondaryAction,
  IconButton,
  ListItemAvatar,
  Avatar,
  Box,
  CircularProgress,
  Typography,
  useMediaQuery,
  Tooltip,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import CreateSessionFromCalendar from "../Calender/CreateSessionFromCalendar";
import TwoMonthCalendar from "./TwoMonthCalendar";
import { useUser } from "../../../UserContext";
import { useNavigate, useParams } from "react-router-dom";
import { LuActivity } from "react-icons/lu";
import { FaCirclePlay, FaRegCircleCheck } from "react-icons/fa6";
import { TranslationContext } from "../../../TranslationContext";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";
import NestedList from "./NestedList";
import Windowopen from "../../../componentHome/commonComponent/Print";
const SessionList = () => {
  const { translations } = useContext(TranslationContext);
  const config = useConfig();
  const { trainingDetails } = useFeedback();
  const {
    sessions,
    sessionType,
    setsessionType,
    setLoading,
    showModal,
    setShowModal,
    sessionDates,
    startDate,
    endDate,
    initialAllowedStartDate,
    initialAllowedEndDate,
    setStartDate,
    setEndDate,
    setInitialAllowedStartDate,
    setInitialAllowedEndDate,
    uncheckedItems,
    setUncheckedItems,
    excludedFacultyIds,
    setExcludedFacultyIds,
    noFacultySessionIds,
    setNoFacultySessionIds,
    allWithoutFacultySelected,
    setAllWithoutFacultySelected,
  } = useFeedback();
  const { loading } = useFeedback();
  const [createbutton, setcreatebutton] = useState(null);
  const [contentlibrarybutton, setcontentlibrarybutton] = useState(null);
  const [durationtype, setdurationtype] = useState(null);
  const { selectedUserType, setSelectedUserType } = useUser();
  const { theme } = useTheme();
  const { id } = useParams();
  const user = JSON.parse(localStorage.getItem("user"));
  const [allSessionChecked, setAllSessionChecked] = useState(true);
  const [selectedFaculties, setSelectedFaculties] = useState(new Set());
  const navigate = useNavigate();

  const getSessionType = async () => {
    const headers = {
      Accept: "application/json, text/plain",
      APIKey: config?.REACT_APP_API_KEY,
      "Content-Type": "application/json",
    };

    try {
      const { data } = await axios.get(
        `${config?.REACT_APP_API_URL}/SessionType`,
        {
          headers: headers,
        }
      );
      setsessionType(data);
    } catch (error) {
      console.log("Error session type", error);
    }
  };

  const getTrgSessionDuration = async () => {
    const headers = {
      Accept: "application/json, text/plain",
      APIKey: config?.REACT_APP_API_KEY,
      "Content-Type": "application/json",
    };

    try {
      const { data } = await axios.get(
        `${config?.REACT_APP_API_URL}/TRG_SESSIONS_DURATION`,
        {
          headers: headers,
          params: {
            trainingid: id,
            durationtype: 2,
          },
        }
      );
      setdurationtype(data);
    } catch (error) {
      console.log("Error session type", error);
    }
  };

  useEffect(() => {
    const fetchdata = async () => {
      await getSessionType();
      await getTrgSessionDuration();
    };
    fetchdata();
  }, []);

  const getCheckCreateButtonPermission = async () => {
    const headers = {
      Accept: "application/json, text/plain",
      APIKey: config?.REACT_APP_API_KEY,
      "Content-Type": "application/json",
    };

    try {
      const { data } = await axios.get(
        `${config?.REACT_APP_API_URL}/CheckPermission`,
        {
          headers: headers,
          params: {
            userid: user?.userdetails?.agencyid,
            usertype: selectedUserType,
            chkpermission: 2,
            formid: "524,8928",
          },
        }
      );
      return data;
    } catch (error) {
      console.log("Error session type", error);
    }
  };

  const getCheckContentLibraryButtonPermission = async () => {
    const headers = {
      Accept: "application/json, text/plain",
      APIKey: config?.REACT_APP_API_KEY,
      "Content-Type": "application/json",
    };

    try {
      const { data } = await axios.get(
        `${config?.REACT_APP_API_URL}/CheckPermission`,
        {
          headers: headers,
          params: {
            userid: user?.userdetails?.agencyid,
            usertype: selectedUserType,
            chkpermission: 1,
            formid: "9623",
          },
        }
      );
      return data;
    } catch (error) {
      console.log("Error session type", error);
    }
  };

  useEffect(() => {
    Promise.all([
      getCheckCreateButtonPermission(),
      getCheckContentLibraryButtonPermission(),
    ])
      .then(([CreateButton, ContentLibrary]) => {
        setcreatebutton(CreateButton);
        setcontentlibrarybutton(ContentLibrary);
      })
      .catch((error) => {
        console.error("Error fetching sessions:", error);
      });
  }, [selectedUserType]);

  useEffect(() => {
    if (allSessionChecked) {
      const allFacultyIds = new Set(
        sessions?.flatMap(
          (session) =>
            session.sessionFaculties?.map((faculty) => faculty.facultyid) || []
        )
      );
      setSelectedFaculties(allFacultyIds); // Select all faculties initially
    }
  }, [allSessionChecked, sessions]);

  const normalizeDate = (date) => new Date(date?.setHours(0, 0, 0, 0));

  const fillAllDates = () => {
    const initialStartDate = normalizeDate(initialAllowedStartDate).getTime();
    const initialEndDate = normalizeDate(initialAllowedEndDate).getTime();
    const currentStartDate = normalizeDate(startDate).getTime();
    const currentEndDate = normalizeDate(endDate).getTime();

    const isStartDateInitial = initialStartDate === currentStartDate;
    const isEndDateInitial = initialEndDate === currentEndDate;

    if (!isStartDateInitial && !isEndDateInitial) {
      setStartDate(initialAllowedStartDate);
      setEndDate(initialAllowedEndDate);
    } else {
      if (isStartDateInitial && isEndDateInitial) {
        setStartDate(null);
        setEndDate(null);
      } else {
        setStartDate(initialAllowedStartDate);
        setEndDate(initialAllowedEndDate);
      }
    }
  };

  // __________________________ Type filter __________________________________

  const [isOpen, setIsOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    if (sessionType) {
      setSelectedItems(Array(sessionType?.length).fill(true));
      setUncheckedItems([]); // Clear unchecked items when sessionType is set
    }
  }, [sessionType]);

  useEffect(() => {
    if (sessionType && selectedItems?.length > 0) {
      const currentUncheckedItems = sessionType?.filter(
        (_, index) => !selectedItems[index]
      );
      setUncheckedItems(currentUncheckedItems);
      setSelectAll(selectedItems.every((item) => item));
    }
  }, [selectedItems, sessionType]);

  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    setSelectedItems(Array(sessionType?.length).fill(newSelectAll));
  };

  const handleSelectItem = (index) => {
    const newSelectedItems = [...selectedItems];
    newSelectedItems[index] = !newSelectedItems[index];
    setSelectedItems(newSelectedItems);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      // If the click is outside the dropdown and the dropdown is open, close it
      if (!event.target.closest(".dropdown-container") && isOpen) {
        setIsOpen(false); // Close the dropdown
      }
    };

    // Add event listener for clicks
    window.addEventListener("mousedown", handleClickOutside);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  // __________________________ Type filter __________________________________

  // __________________________ Faculty filter __________________________________

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [facultySelections, setFacultySelections] = useState([]);
  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  useEffect(() => {
    if (sessions) {
      const initialSelections = sessions
        ?.flatMap((session) => session?.sessionFaculties)
        .map(() => true);
      setFacultySelections(initialSelections);
    }
  }, [sessions]);

  useEffect(() => {
    const currentExcluded =
      sessions
        ?.flatMap((session) =>
          session?.sessionFaculties?.map((faculty) => faculty?.facultyid)
        )
        ?.filter((_, index) => !facultySelections[index]) || [];

    const sessionsWithNoFaculties = sessions
      ?.filter((session) => session?.sessionFaculties?.length === 0)
      .map((session) => session?.ttttt_session_id);

    setExcludedFacultyIds(currentExcluded);
    setNoFacultySessionIds(sessionsWithNoFaculties);
  }, [facultySelections, sessions]);

  const toggleFacultySelection = (index) => {
    const updatedSelections = [...facultySelections];
    updatedSelections[index] = !updatedSelections[index];
    setFacultySelections(updatedSelections);
  };

  useEffect(() => {
    setAllWithoutFacultySelected(true);
  }, []);

  const toggleSelectAll = () => {
    const allSelected = facultySelections?.every(Boolean);
    const newSelections = Array(facultySelections?.length).fill(!allSelected);

    // Check if all items in newSelections are true or false
    const allTrue = newSelections?.every(Boolean);
    const allFalse = newSelections?.every((item) => !item);

    if (allTrue) {
      setAllWithoutFacultySelected(true); // Change to false when all are selected
      setFacultySelections(newSelections); // Call here when all are selected
    } else if (allFalse) {
      if (!allWithoutFacultySelected) {
        setAllWithoutFacultySelected(true); // Change to true when none are selected
        setFacultySelections(Array(facultySelections?.length).fill(true)); // Set to true array
      } else {
        setAllWithoutFacultySelected(false); // Change to false when selections are made
        setFacultySelections(newSelections); // Call here when none are selected
      }
    } else {
      setAllWithoutFacultySelected(false); // Handle mixed case
      setFacultySelections(newSelections); // Call in mixed case as well
    }
  };

  const toggleSelectAllWithoutFaculty = () => {
    setAllWithoutFacultySelected((prev) => !prev);
  };

  const filteredSessions = sessions?.filter((session) => {
    const hasNoFaculties = session?.sessionFaculties?.length === 0;
    const isExcluded = excludedFacultyIds?.some((facultyId) =>
      session.sessionFaculties.some(
        (faculty) => faculty.facultyid === facultyId
      )
    );

    return !allWithoutFacultySelected || hasNoFaculties || isExcluded;
  });

  useEffect(() => {
    const handleClickOutside = (event) => {
      // If the click is outside the dropdown and the dropdown is open, close it
      if (
        !event.target.closest(".dropdown-faculty-container") &&
        dropdownOpen
      ) {
        setDropdownOpen(false); // Close the dropdown
      }
    };

    // Add event listener for clicks
    window.addEventListener("mousedown", handleClickOutside);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownOpen]);

  // __________________________ Faculty filter __________________________________

  const ScreenRef = useRef(null);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = String(date.getFullYear()).slice(2);
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    // return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
    return `${day}-${month}-${year} `;
  };

  const formatTime = (inputString) => {
    let date;

    // Check if the input is in "YYYY-MM-DD HH:mm:ss" format (no 'T' separator)
    if (inputString.includes(" ")) {
      // For "YYYY-MM-DD HH:mm:ss", replace the space with 'T' to make it ISO format
      inputString = inputString.replace(" ", "T");
      date = new Date(inputString); // Parse as an ISO string
    } else {
      // If it's just a time string (HH:mm or HH:mm AM/PM), handle it
      const timeRegex = /^(\d{2}):(\d{2})(\s?[APMapm]{2})?$/;

      const match = inputString.match(timeRegex);

      if (!match) {
        return "Invalid time format"; // If input doesn't match expected time format
      }

      let [_, hours, minutes, ampm] = match;
      hours = parseInt(hours);
      minutes = parseInt(minutes);

      // If the time is in 12-hour format (AM/PM), adjust hours accordingly
      if (ampm) {
        ampm = ampm.toUpperCase().trim();
        if (ampm === "PM" && hours !== 12) {
          hours += 12; // Convert PM hours to 24-hour format
        } else if (ampm === "AM" && hours === 12) {
          hours = 0; // Convert 12 AM to 0 (midnight)
        }
      }

      // Ensure hours and minutes are valid
      if (
        isNaN(hours) ||
        isNaN(minutes) ||
        hours < 0 ||
        hours > 23 ||
        minutes < 0 ||
        minutes > 59
      ) {
        return "Invalid time format";
      }

      // Create a valid date object from parsed time
      date = new Date(
        `1970-01-01T${String(hours).padStart(2, "0")}:${String(
          minutes
        ).padStart(2, "0")}:00`
      );
    }

    // If date parsing failed, return an error message
    if (isNaN(date.getTime())) {
      return "Invalid time format";
    }

    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";

    // Convert to 12-hour format
    hours = hours % 12;
    hours = hours ? String(hours).padStart(2, "0") : "12"; // '0' becomes '12'

    return `${hours}:${minutes} ${ampm}`;
  };

  const { printcontext, setprintcontext } = useUser();

  const printTable = () => {
    // const printWindow = window.open("", "_blank");

    // Define session type mapping
    const sessionTypeMapping = {
      Academic: translations["Personled"] || "Person led",
      Break: translations["Breaks"] || "Break",
      Tour: translations["Tour"] || "Tour",
      GroupDiscussion: translations["Group_discussion"] || "Group Discussion",
      Assignment: translations["Assignment"] || "Assignment",
      PT: translations["PT"] || "PT",
      Sports: translations["Sports"] || "Sport",
      Practical: translations["Practical"] || "Practical",
      SelfPaced: translations["Self_paced"] || "Self paced",
    };

    // Initialize visibility flags for each column
    let columnVisibility = {
      Day: false,
      Week: false,
      Module: false,
      srno: false,
      // Fixed columns: these are always visible, so no condition is applied to them
      Duration: true,
      Subject: true,
      Type: true,
      Faculty: true,
      Date: false,
    };

    // Loop through all sessions to determine which columns should be shown
    sessions.forEach((session) => {
      const displayInfo = session.displayInfos || [];

      displayInfo.forEach((info) => {
        if (info.value) {
          columnVisibility[info.name] = true;
        }
      });
    });

    // Remove duplicate sessions based on session ID (or any unique identifier)
    const uniqueSessions = sessions.filter(
      (session, index, self) =>
        index ===
        self.findIndex((t) => t.ttttt_session_id === session.ttttt_session_id)
    );

    // Prepare session rows
    const sessionRows = uniqueSessions
      ?.map((session, index) => {
        const displayInfo = session.displayInfos || {};

        const faculties = session?.sessionFaculties
          ?.map((faculty, facultyIndex) => {
            return `<span key="${facultyIndex}">${faculty.facultyname}</span>`;
          })
          .join(", "); // Join faculty names with commas

        // Use sessionTypeMapping for the session type
        const sessionType =
          sessionTypeMapping[session.session_type_name] ||
          session.session_type_name ||
          "Unknown";

        // Check if session.ttttt_type is 6 or 7 to conditionally add the start and end date
        let sessionContentDesc = `${session.ttttt_subject} - ${session.ttttt_content_desc}`;
        if (session.ttttt_type === 6 || session.ttttt_type === 7) {
          const startDate = formatDate(session.ttttt_session_dt);
          const endDate = formatDate(session.ttttt_session_end_time);
          const startTime = formatTime(session.ttttt_session_time);
          const endTime = formatTime(session.ttttt_session_end_time);
          sessionContentDesc += `<br>Start Date: ${startDate} ${startTime} - Last Date: ${endDate} ${endTime}`;
        }

        return `
          <tr key="${session.ttttt_session_id || index}">
            ${
              columnVisibility["Day"]
                ? `<td>${session.ttttt_session_day}</td>`
                : ""
            }
            ${
              columnVisibility["Week"]
                ? `<td>${session.ttttt_session_week}</td>`
                : ""
            }
            ${columnVisibility["Module"] ? `<td>${session.module}</td>` : ""}
            ${
              columnVisibility["srno"]
                ? `<td>${session.ttttt_session_no}</td>`
                : ""
            }
            ${
              columnVisibility["Date"]
                ? `<td>${formatDate(session.ttttt_session_dt)}</td>`
                : ""
            }
            ${
              columnVisibility["Duration"]
                ? `<td>${session.ttttt_session_duration} ${session.ttttt_session_duration_type_name}</td>`
                : ""
            }
            ${
              columnVisibility["Subject"]
                ? `<td>${sessionContentDesc}</td>`
                : ""
            }
            ${columnVisibility["Type"] ? `<td>${sessionType}</td>` : ""}
            ${columnVisibility["Faculty"] ? `<td>${faculties}</td>` : ""}
          </tr>
        `;
      })
      .join("");

    const printStr = `
        <html>
          <head>
            <title>Session List Table</title>
            <style>
              body {
                font-family: Arial, sans-serif;
                margin: 20px;
              }
              table {
                width: 100%;
                border-collapse: collapse;
              }
              th, td {
                border: 1px solid #ddd;
                padding: 8px;
                text-align: center;
              }
              th {
                background-color: #f2f2f2;
              }
              h2 {
                text-align: center;
                margin-bottom: 20px;
              }
              h3 {
                text-align: center;
                margin-bottom: 30px;
              }
            </style>
          </head>
          <body>
            <h2>${
              trainingDetails?.courseName || "Course Name Not Available"
            } - ${
      trainingDetails?.trainingcode || "Training Code Not Available"
    }</h2>
            <h3>Time Table Duration: ${
              formatDate(trainingDetails?.t_StartDate) ||
              "Start Date Not Available"
            } - ${
      formatDate(trainingDetails?.t_EndDate) || "End Date Not Available"
    }</h3>
    
            <table>
              <thead>
                <tr>
                  ${
                    columnVisibility["Day"]
                      ? `<th>${translations["Days"] || "Days"}</th>`
                      : ""
                  }
                  ${
                    columnVisibility["Week"]
                      ? `<th>${translations["Week"] || "Week"}</th>`
                      : ""
                  }
                  ${
                    columnVisibility["Module"]
                      ? `<th>${translations["Module"] || "Module"}</th>`
                      : ""
                  }
                  ${
                    columnVisibility["srno"]
                      ? `<th>${translations["Srno"] || "Sr No"}</th>`
                      : ""
                  }
                  ${
                    columnVisibility["Date"]
                      ? `<th>${translations["Date"] || "Date"}</th>`
                      : ""
                  }
                  ${
                    columnVisibility["Duration"]
                      ? `<th>${translations["Duration"] || "Duration"}</th>`
                      : ""
                  }
                  ${
                    columnVisibility["Subject"]
                      ? `<th>${translations["Subject"] || "Subject"}</th>`
                      : ""
                  }
                  ${
                    columnVisibility["Type"]
                      ? `<th>${translations["Type"] || "Type"}</th>`
                      : ""
                  }
                  ${
                    columnVisibility["Faculty"]
                      ? `<th>${translations["Faculty"] || "Faculty"}</th>`
                      : ""
                  }
                </tr>
              </thead>
              <tbody>
                ${sessionRows}
              </tbody>
            </table>
          </body>
        </html>
      `;
    //setprintcontext(printStr)
    printData(printStr, config);
    // Print HTML document
    // printWindow.document.write(`
    //     <html>
    //       <head>
    //         <title>Session List Table</title>
    //         <style>
    //           body {
    //             font-family: Arial, sans-serif;
    //             margin: 20px;
    //           }
    //           table {
    //             width: 100%;
    //             border-collapse: collapse;
    //           }
    //           th, td {
    //             border: 1px solid #ddd;
    //             padding: 8px;
    //             text-align: center;
    //           }
    //           th {
    //             background-color: #f2f2f2;
    //           }
    //           h2 {
    //             text-align: center;
    //             margin-bottom: 20px;
    //           }
    //           h3 {
    //             text-align: center;
    //             margin-bottom: 30px;
    //           }
    //         </style>
    //       </head>
    //       <body>
    //         <h2>${
    //           trainingDetails?.courseName || "Course Name Not Available"
    //         } - ${
    //   trainingDetails?.trainingcode || "Training Code Not Available"
    // }</h2>
    //         <h3>Time Table Duration: ${
    //           formatDate(trainingDetails?.t_StartDate) ||
    //           "Start Date Not Available"
    //         } - ${
    //   formatDate(trainingDetails?.t_EndDate) || "End Date Not Available"
    // }</h3>

    //         <table>
    //           <thead>
    //             <tr>
    //               ${
    //                 columnVisibility["Day"]
    //                   ? `<th>${translations["Days"] || "Days"}</th>`
    //                   : ""
    //               }
    //               ${
    //                 columnVisibility["Week"]
    //                   ? `<th>${translations["Week"] || "Week"}</th>`
    //                   : ""
    //               }
    //               ${
    //                 columnVisibility["Module"]
    //                   ? `<th>${translations["Module"] || "Module"}</th>`
    //                   : ""
    //               }
    //               ${
    //                 columnVisibility["srno"]
    //                   ? `<th>${translations["Srno"] || "Sr No"}</th>`
    //                   : ""
    //               }
    //               ${
    //                 columnVisibility["Date"]
    //                   ? `<th>${translations["Date"] || "Date"}</th>`
    //                   : ""
    //               }
    //               ${
    //                 columnVisibility["Duration"]
    //                   ? `<th>${translations["Duration"] || "Duration"}</th>`
    //                   : ""
    //               }
    //               ${
    //                 columnVisibility["Subject"]
    //                   ? `<th>${translations["Subject"] || "Subject"}</th>`
    //                   : ""
    //               }
    //               ${
    //                 columnVisibility["Type"]
    //                   ? `<th>${translations["Type"] || "Type"}</th>`
    //                   : ""
    //               }
    //               ${
    //                 columnVisibility["Faculty"]
    //                   ? `<th>${translations["Faculty"] || "Faculty"}</th>`
    //                   : ""
    //               }
    //             </tr>
    //           </thead>
    //           <tbody>
    //             ${sessionRows}
    //           </tbody>
    //         </table>
    //       </body>
    //     </html>
    //   `);

    // Wait for the window to load and trigger the print dialog
    // printWindow.document.close(); // Important to close the document for proper rendering
    // printWindow.print(); // Open the print dialog
  };

  const downloadExcel = () => {
    // Define session type mapping
    const sessionTypeMapping = {
      Academic: translations["Personled"] || "Person led",
      Break: translations["Breaks"] || "Break",
      Tour: translations["Tour"] || "Tour",
      GroupDiscussion: translations["Group_discussion"] || "Group Discussion",
      Assignment: translations["Assignment"] || "Assignment",
      PT: translations["PT"] || "PT",
      Sports: translations["Sports"] || "Sport",
      Practical: translations["Practical"] || "Practical",
      SelfPaced: translations["Self_paced"] || "Self paced",
    };

    // Initialize visibility flags for each column
    let columnVisibility = {
      Day: false,
      Week: false,
      Module: false,
      srno: false,
      // Fixed columns: these are always visible, so no condition is applied to them
      Duration: true,
      Subject: true,
      Type: true,
      Faculty: true,
      Date: false,
    };

    // Loop through all sessions to determine which columns should be shown
    sessions.forEach((session) => {
      const displayInfo = session.displayInfos || [];

      displayInfo.forEach((info) => {
        if (info.value) {
          columnVisibility[info.name] = true;
        }
      });
    });

    // Remove duplicate sessions based on session ID (or any unique identifier)
    const uniqueSessions = sessions.filter(
      (session, index, self) =>
        index ===
        self.findIndex((t) => t.ttttt_session_id === session.ttttt_session_id)
    );

    // Prepare the rows for the Excel file, including headers
    const rows = [
      // Header row for Excel (same as the table headers)
      [
        columnVisibility["Day"] ? translations["Days"] || "Days" : null,
        columnVisibility["Week"] ? translations["Week"] || "Week" : null,
        columnVisibility["Module"] ? translations["Module"] || "Module" : null,
        columnVisibility["srno"] ? translations["Srno"] || "Sr No" : null,
        columnVisibility["Date"] ? translations["Date"] || "Date" : null,
        columnVisibility["Duration"]
          ? translations["Duration"] || "Duration"
          : null,
        columnVisibility["Subject"]
          ? translations["Subject"] || "Subject"
          : null,
        columnVisibility["Type"] ? translations["Type"] || "Type" : null,
        columnVisibility["Faculty"]
          ? translations["Faculty"] || "Faculty"
          : null,
      ].filter(Boolean), // Filter out nulls for columns that are not visible

      // Data rows (loop through unique sessions)
      ...(uniqueSessions.map((session) => {
        const sessionType =
          sessionTypeMapping[session.session_type_name] ||
          session.session_type_name ||
          "Unknown";
        const faculties =
          session?.sessionFaculties
            ?.map((faculty) => faculty.facultyname)
            .join(", ") || "";

        // Prepare session row based on column visibility
        const row = [];
        if (columnVisibility["Day"]) row.push(session.ttttt_session_day);
        if (columnVisibility["Week"]) row.push(session.ttttt_session_week);
        if (columnVisibility["Module"]) row.push(session.module);
        if (columnVisibility["srno"]) row.push(session.ttttt_session_no);
        if (columnVisibility["Date"])
          row.push(formatDate(session.ttttt_session_dt));
        if (columnVisibility["Duration"])
          row.push(
            `${session.ttttt_session_duration} ${session.ttttt_session_duration_type_name}`
          );
        if (columnVisibility["Subject"])
          row.push(`${session.ttttt_subject} - ${session.ttttt_content_desc}`);
        if (columnVisibility["Type"]) row.push(sessionType); // Mapped session type here
        if (columnVisibility["Faculty"]) row.push(faculties);

        return row;
      }) || []),
    ];

    // Course details row (No need to span across columns in Excel)
    const courseDetails = [
      [`${trainingDetails?.courseName} - ${trainingDetails?.trainingcode}`],
      [
        `Time Table Duration :  ${
          formatDate(trainingDetails?.t_StartDate) || "Start Date Not Available"
        } - ${
          formatDate(trainingDetails?.t_EndDate) || "End Date Not Available"
        }`,
      ], // 1 column
    ];

    // Combine course details and session data
    const finalRows = [
      ...courseDetails,
      [],
      // Add a blank row for separation
      ...rows,
    ];

    // Create a worksheet and workbook
    const ws = XLSX.utils.aoa_to_sheet(finalRows); // Array of arrays (rows)
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Session List");

    // Download the file as Excel
    XLSX.writeFile(wb, "session_list.xlsx");
  };

  const handleError = () => {
    toast.warning("No sessions Found");
  };

  useEffect(() => {
    return () => {
      document.body.style.overflowY = "auto";
    };
  }, []);

  const [highlight, setHighlight] = useState(true);

  useEffect(() => {
    // Animation is always on with infinite flicker
    setHighlight(true);
    return () => {};
  }, []);

  const [showfilters, setshowfilters] = useState(true);

  const filtertoggler = () => {
    setshowfilters((prev) => !prev);
  };

  const handleResize = () => {
    if (window.innerWidth < 640) {
      setshowfilters(false);
    } else {
      setshowfilters(true);
    }
  };

  // Set up event listener on mount and cleanup on unmount
  useEffect(() => {
    // Initial check on component mount
    handleResize();

    // Listen for window resize events
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="h-full w-full">
      <div className="w-full flex justify-end">
        <button
          style={{ backgroundColor: theme?.palette?.primary?.main }}
          onClick={filtertoggler}
          className="flex items-center text-white px-4 py-2 mt-2 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300 "
        >
          <FaFilter className="h-5 w-5 mr-2" />
          {showfilters ? "Hide Filters" : "Show Filters"}
        </button>
      </div>
      <div
        className={`
          ${showfilters ? "flex" : "hidden"} 
          ${
            trainingDetails?.trg_Setting?.session?.sessionEntry?.date
              ? "justify-between max-lg:grid max-lg:grid-cols-1"
              : "justify-start gap-10 max-lg:grid max-sm:grid-cols-1"
          }   
          max-lg:gap-4 items-center py-6`}
      >
        <div
          className={`${
            trainingDetails?.trg_Setting?.session?.sessionEntry?.date
              ? "flex "
              : "hidden"
          } max-sm:gap-4 max-sm:items-start max-sm:flex-col gap-10 items-center`}
        >
          <div className="flex max-sm:items-start max-sm:gap-4 gap-10 max-sm:flex-col items-center">
            <span className="text-2xl">
              {translations["Trg_period"] || "Training Period"}
            </span>
            <TwoMonthCalendar />
          </div>

          <div className="flex  items-center gap-4">
            <input
              checked={
                  normalizeDate(initialAllowedStartDate).getTime() ===
                  normalizeDate(startDate).getTime() &&
                  normalizeDate(initialAllowedEndDate).getTime() ===
                  normalizeDate(endDate).getTime()
              }
              onClick={fillAllDates}
              className="mt-[2px] max-sm:mt-0"
              type="checkbox"
            />
            <span>
              {translations["Show_all_session"] || "Show All Sessions"}
            </span>
          </div>
        </div>

        <div className="relative inline-block text-left dropdown-container">
          <button
            className="inline-flex w-full max-lg:max-w-72 max-lg:justify-between pl-4 max-mid:max-w-96 items-center relative gap-x-2 overflow-hidden rounded-md bg-white px-2.5 py-3 text-sm font-medium capitalize text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 whitespace-nowrap"
            onClick={() => setIsOpen(!isOpen)}
          >
            {translations["Filter_by_Type"] || "Filter By Type"}
            <ChevronDownIcon
              aria-hidden="true"
              className="max-mid:absolute top-1.5 right-[.6px] max-mid:h-[70%] bg-white flex items-center w-7 text-black"
            />
          </button>

          {/* Dropdown Menu */}
          {isOpen && (
            <div style={{zIndex:'999'}} className="absolute bg-white max-phone:w-[90vw] min-h-fit max-h-[60vh] overflow-y-auto left-0 origin-top-right divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none">
              <div className="py-1 w-full">
              
                <button
                  className="block px-4 flex-shrink-0 whitespace-nowrap text-sm text-gray-700 w-full"
                  onClick={handleSelectAll}
                >
                  <span className="flex py-3 items-center gap-2">
                    <input
                      type="checkbox"
                      checked={selectAll}
                      readOnly
                      className="mr-2 mt-0"
                    />
                    <span className="flex items-center">
                      {translations["All"] || "All"}
                    </span>
                  </span>
                </button>

                {/* Session Type Items */}
                {sessionType.map((session, index) => (
                  <button
                    key={session.id}
                    className="block px-4 flex-shrink-0 whitespace-nowrap text-sm text-gray-700 w-full"
                    onClick={() => handleSelectItem(index)}
                  >
                    <span className="flex py-3 items-center gap-2">
                      <input
                        type="checkbox"
                        checked={selectedItems[index]}
                        readOnly
                        className="mr-2 mt-0"
                      />
                      <i className={session.displayClass}></i>
                      <span>
                        {session.name === "Academic"
                          ? translations["Personled"] || "Person led"
                          : session.name === "Break"
                          ? translations["Breaks"] || "Break"
                          : session.name === "Tour"
                          ? translations["Tour"] || "Tour"
                          : session.name === "GroupDiscussion"
                          ? translations["Group_discussion"] ||
                            "Group Discussion"
                          : session.name === "Presentation"
                          ? translations["Presentation"] || "Presentation"
                          : session.name === "Assignment"
                          ? translations["Assignment"] || "Assignment"
                          : session.name === "Test"
                          ? translations["Test"] || "Test"
                          : session.name === "PT"
                          ? translations["PT"] || "PT"
                          : session.name === "Sports"
                          ? translations["Sport"] || "Sports"
                          : session.name === "Practical"
                          ? translations["Practical"] || "Practical"
                          : session.name === "SelfPaced"
                          ? translations["Self_paced"] || "Self Paced"
                          : session.name}
                      </span>
                    </span>
                  </button>
                ))}
              </div>
            </div>
          )}
        </div>

        <div className="relative inline-block text-left dropdown-faculty-container">
          <button
            onClick={toggleDropdown}
            className="inline-flex w-full max-lg:max-w-72 max-lg:justify-between pl-4 max-mid:max-w-96 items-center relative gap-x-2 overflow-hidden rounded-md bg-white px-2.5 py-3 text-sm font-medium capitalize text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 whitespace-nowrap"
          >
            {translations["Filter_by_faculty"] || "Filter By Speaker"}
            <ChevronDownIcon
              aria-hidden="true"
              className="max-mid:absolute top-1.5 right-[.6px] max-mid:h-[70%] bg-white flex items-center w-7 text-black"
            />
          </button>

          {dropdownOpen && (
            <div
              style={{zIndex:'999'}} 
              className="absolute bg-white max-phone:w-[90vw] min-h-fit max-h-[60vh] overflow-y-auto left-0 origin-top-right divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none">
              <div className="py-1 w-full">
                <button
                  className="block px-4 flex-shrink-0 whitespace-nowrap text-sm text-gray-700 w-full"
                  onClick={toggleSelectAll}
                >
                  <span className="flex py-3 items-center gap-2">
                    <input
                      type="checkbox"
                      checked={
                        facultySelections.every(Boolean) &&
                        allWithoutFacultySelected
                      }
                      readOnly
                      className="mr-2 mt-0"
                    />
                    <span className="flex items-center">
                      {translations["All"] || "All"}
                    </span>
                  </span>
                </button>

                <button
                  className="block px-4 flex-shrink-0 whitespace-nowrap text-sm text-gray-700 w-full"
                  onClick={toggleSelectAllWithoutFaculty}
                >
                  <span className="flex py-3 items-center gap-2">
                    <input
                      type="checkbox"
                      checked={allWithoutFacultySelected}
                      readOnly
                      className="mr-2 mt-0"
                    />
                    <span>All Without Faculty</span>
                  </span>
                </button>

                {[
                  ...new Set(
                    sessions?.flatMap(
                      (session) =>
                        session.sessionFaculties?.map(
                          (faculty) => faculty.facultyid
                        ) || []
                    )
                  ),
                ].map((facultyId, index) => {
                  const faculty = sessions
                    ?.flatMap((session) => session.sessionFaculties)
                    .find((f) => f.facultyid === facultyId);
                  return (
                    <button
                      key={faculty.facultyid}
                      className="block px-4 flex-shrink-0 whitespace-nowrap text-sm text-gray-700 w-full"
                      onClick={() => toggleFacultySelection(index)}
                    >
                      <span className="flex py-3 items-center gap-2">
                        <input
                          type="checkbox"
                          checked={facultySelections[index]}
                          className="mr-2 mt-0"
                          readOnly
                        />
                        <span>{faculty?.facultyname}</span>
                      </span>
                    </button>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>

      <div
        className={`w-full bg-gray-300 h-[.5px] ${
          showfilters ? "mt-0" : "mt-6"
        }  `}
      ></div>

      <div className="w-full flex flex-col py-6 gap-2">
        <div className="flex justify-between max-lg:flex-col max-lg:items-start max-lg:gap-4 items-center ">
          <div className="flex items-center gap-2 max-lg:grid max-sm:grid-cols-2 max-lg:grid-cols-3 ">
            {createbutton === null ||
            (Array.isArray(createbutton) &&
              createbutton?.length === 0) ? null : (
              <>
                <button
                  title={translations["Create_Session"] || "Create Session"}
                  style={{ backgroundColor: theme?.palette?.primary?.main }}
                  className="text-white py-3 px-5 rounded-lg"
                  // onClick={()=>setShowModal(true)}
                >
                  {translations["Create_Session"] || "Create Session"}
                </button>

                <button
                  title={translations["Import_session"] || "Import Session"}
                  style={{ backgroundColor: theme?.palette?.primary?.main }}
                  className="text-white py-3 px-5 rounded-lg"
                >
                  {translations["Import_session"] || "Import Session"}
                </button>

                <button
                  title={
                    translations["Recurring_session"] || "Recurring Session"
                  }
                  style={{ backgroundColor: theme?.palette?.primary?.main }}
                  className="text-white py-3 px-5 rounded-lg"
                >
                  {translations["Recurring_session"] || "Recurring Session"}
                </button>

                <button
                  title={translations["Import_session"] || "Import_from_suit"}
                  style={{ backgroundColor: theme?.palette?.primary?.main }}
                  className="text-white flex items-center gap-4 py-3 px-5 rounded-lg"
                >
                  <FaSuitcase />
                  {translations["Import_session"] || "Import_from_suit"}
                </button>

                <button
                  title={translations["Add_participant"] || "Add Participant"}
                  style={{ backgroundColor: theme?.palette?.primary?.main }}
                  className="text-white flex items-center gap-2 py-3 px-5 rounded-lg"
                >
                  <FaUser />
                  {translations["Add_participant"] || "Add Participant"}
                </button>
              </>
            )}
          </div>
          {selectedUserType != 5 &&
            (contentlibrarybutton === null ||
            (Array.isArray(contentlibrarybutton) &&
              contentlibrarybutton.length === 0) ? null : (
              <button
                title={translations["Content_library"] || "Content Library"}
                style={{ backgroundColor: theme?.palette?.primary?.main }}
                className="text-white py-3 px-5 rounded-lg"
              >
                {translations["Content_library"] || "Content Library"}
              </button>
            ))}
        </div>

        <div className="flex max-lg:flex-col max-lg:items-start justify-between items-end gap-2">
          <p
            style={{ color: theme?.palette?.primary?.main }}
            className="animate_animated animate_infinite animate-pulse flex flex-wrap items-center m-0 max-phone:pb-4 max-phone:text-lg"
          >
            To View/Read Content Click On Littera Room Icon
            <span className="flex items-center ml-2">
              (<IoRocketSharp />)
            </span>
          </p>

          <div
            className={`phone:hidden gap-2
              ${
                durationtype?.theory > 0 &&
                durationtype?.activity > 0 &&
                durationtype?.practical > 0
                  ? "flex flex-col"
                  : "flex"
              }`}
          >
            <div className="flex gap-2">
              {/* {durationtype?.activity <= 0 && ( */}
              {durationtype?.activity > 0 && (
                <button
                  title={`Activity ${durationtype?.activity > 1 ? durationtype?.activity : durationtype?.activity * 60} - ${durationtype?.activity > 1 ? durationtype?.durationtype : 'Min'}`}
                  className="text-white bg-[#808080] py-3 px-5 flex items-center gap-4 rounded-lg"
                >
                  {/* <LuActivity /> {durationtype?.activity} -{" "} */}
                  <LuActivity /> {durationtype?.activity > 1 ? durationtype?.activity : durationtype?.activity * 60} -{" "}
                  {/* {durationtype?.durationtype} */}
                  {durationtype?.activity > 1 ? durationtype?.durationtype : 'Min'}
                </button>
              )}

              {/* {durationtype?.practical <= 0 && ( */}
              {durationtype?.practical > 0 && (
                <button
                  title={`Practical ${durationtype?.practical > 1 ? durationtype?.practical : durationtype?.practical * 60} - ${durationtype?.practical > 1 ? durationtype?.durationtype : 'Min'}`}

                  // title={`Practical ${durationtype?.practical} - ${durationtype?.durationtype}`}
                  className="text-white bg-[#808080] py-3 px-5 flex items-center gap-4 rounded-lg"
                >
                  <IoFlask /> {durationtype?.practical} -{" "}
                  {/* {durationtype?.durationtype} */}
                  {durationtype?.practical > 1 ? durationtype?.durationtype : 'Min'}
                </button>
              )}

              {/* {durationtype?.theory <= 0 && ( */}
              {durationtype?.theory > 0 && (
                <button
                  title={`Practical ${durationtype?.theory > 1 ? durationtype?.theory : durationtype?.theory * 60} - ${durationtype?.theory > 1 ? durationtype?.durationtype : 'Min'}`}
                // title={`Theory ${durationtype?.theory} - ${durationtype?.durationtype}`}
                  className="text-white bg-[#808080] py-3 px-5 flex items-center gap-4 rounded-lg"
                >
                  <FaBook />
                  {/* {durationtype?.theory} - {durationtype?.durationtype} */}
                  {durationtype?.theory} - {" "}
                  {durationtype?.theory > 1 ? durationtype?.durationtype : 'Min'}

                </button>
              )}
            </div>

            <div className="flex gap-2">
              <button
                onClick={sessions?.length > 0 ? printTable : handleError}
                title="Print"
                className="text-white bg-[#808080] py-3 px-5 w-fit flex items-center gap-4 rounded-lg"
              >
                <FaPrint />
              </button>

              <button
                onClick={sessions?.length > 0 ? downloadExcel : handleError}
                title="Save as excel"
                className="text-white bg-[#808080] py-3 px-5 w-fit flex items-center gap-4 rounded-lg"
              >
                <RiFileExcel2Fill />
              </button>
            </div>
          </div>

          <div className="flex max-phone:hidden max-sm:grid max-sm:grid-cols-1 gap-2">
            <div className="flex max-phone:grid max-phone:grid-cols-1 gap-2">
              {/* {durationtype?.activity <= 0 && ( */}
              {durationtype?.activity > 0 && (
                <button
                  title={`Activity ${durationtype?.activity > 1 ? durationtype?.activity : durationtype?.activity * 60} - ${durationtype?.practical > 1 ? durationtype?.durationtype : 'Min'}`}
                  className="text-white bg-[#808080] py-3 px-5 flex items-center gap-4 rounded-lg"
                >
                  <LuActivity /> {translations["Activity"] || "Activity"}{" "}
                  {durationtype?.activity > 1 ? durationtype?.activity : durationtype?.activity * 60} -{" "}
                  {durationtype?.activity > 1 ? durationtype?.durationtype : 'Min'}
                </button>
              )}

              {/* {durationtype?.practical <= 0 && ( */}
              {durationtype?.practical > 0 && (
                <button
                  title={`Practical ${durationtype?.practical > 1 ? durationtype?.practical : durationtype?.practical * 60} - ${durationtype?.practical > 1 ? durationtype?.durationtype : 'Min'}`}
                // title={`Practical ${durationtype?.practical} - ${durationtype?.durationtype}`}
                  className="text-white bg-[#808080] py-3 px-5 flex items-center gap-4 rounded-lg"
                >
                  <IoFlask /> {translations["Practical"] || "Practical"}{" "}
                  {durationtype?.practical > 1 ? durationtype?.practical : durationtype?.practical * 60} -{" "}
                  {durationtype?.practical > 1 ? durationtype?.durationtype : 'Min'}
                  {/* {durationtype?.practical} - {durationtype?.durationtype} */}
                </button>
              )}

              {/* {durationtype?.theory <= 0 && ( */}
              {durationtype?.theory > 0 && (
                <button
                  title={`Theory ${durationtype?.theory > 1 ? durationtype?.theory : durationtype?.theory * 60} - ${durationtype?.theory > 1 ? durationtype?.durationtype : 'Min'}`}
                  // title={`Theory ${durationtype?.theory} - ${durationtype?.durationtype}`}
                  className="text-white bg-[#808080] py-3 px-5 flex items-center gap-4 rounded-lg"
                >
                  <FaBook />
                  {translations["Theory"] || "Theory"}{" "}
                  {durationtype?.theory > 1 ? durationtype?.theory : durationtype?.theory * 60} -{" "}
                  {durationtype?.theory > 1 ? durationtype?.durationtype : 'Min'}

                  {/* {durationtype?.theory} -{" "}
                  {durationtype?.durationtype} */}
                </button>
              )}
            </div>

            <div className="flex gap-2">
              <button
                onClick={sessions?.length > 0 ? printTable : handleError}
                title="Print"
                className="text-white bg-[#808080] py-3 px-5 w-fit flex items-center gap-4 rounded-lg"
              >
                <FaPrint />
              </button>

              <button
                onClick={sessions?.length > 0 ? downloadExcel : handleError}
                title="Save as excel"
                className="text-white bg-[#808080] py-3 px-5 w-fit flex items-center gap-4 rounded-lg"
              >
                <RiFileExcel2Fill />
              </button>
            </div>
          </div>
        </div>
      </div>

      {showModal ? <CreateSessionFromCalendar /> : null}
      <div ref={ScreenRef}>
        <NestedList />
      </div>
    </div>
  );
};

export default SessionList;
